/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useEffect, useState } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import PreContentComponent from '../data/PreContentComponent';
import HeroBanner from './HeroBanner';
import '../../css/user_guide.css';

function UserGuide() {
  const [htmlData, setHtmlData] = useState('<div></div>');

  useEffect(() => {
    (async () => {
      const response = await fetch('static/user-guide.html');
      const text = await response.text();
      setHtmlData(text);
    })();
  }, []);

  const singleColumnFilter = `{
    "field":"industry",
    "operator":"eq",
    "value":"B"
}`;

  const singleColumnFilter2 = `{
      "field":"industry",
      "operator":"neq",
      "value":"B"
}`;
  const singleColumnFilter3 = `{
    "field":"industry",
    "operator":"in",
    "value":[“A”,”B",”C”]
}`;

  const singleColumnFilter4 = `{
    "field":"industry",
    "operator":"not_in",
    "value":[“A”,”B",”C”]     
}`;

  const singleColumnFilter5 = `{
    "field":"industry",
    "operator":"like",
    "value":"%A%"
}`;

  const multipleColumnFilter = `{
    "and":[
        {
            "field":"industry",
            "operator":"eq",
            "value":"B"
        },
        {
            "field":"year",
            "operator":"eq",
            "value":"2020"
        }
      ]
  }
    `;
  const multipleColumnMultipleFilter = `{
      "and":[
        {
            "field":"year",
            "operator":"eq",
            "value":"2021"
        },
        {
            "or":[
            {
                "field":"industry",
                "operator":"eq",
                "value":"A"
            },
            {
                "field":"industry",
                "operator":"eq",
                "value":"C"
            }
          ]
      }
    ]
}
    `;

  const singleColumnMultipleFilter = `{
      "or":[
      {
        "field":"value",
        "operator":"lt",
        "value":500
      },
      {
        "field":"value",
        "operator":"gt",
        "value":999
      }
    ]
}
    `;

  const finalQuery = `https://apiprod.dol.gov/v4/get/agency/dataset/json?limit=10&offset=0&sort=asc&sort_by=industry&filter_object={"and":[{"or":[{"field":"industry","operator":"eq","value":"A"}, {"field":"industry","operator":
  "eq","value":"C"}]}, {"field":"year","operator":"eq","value":"2021"}]}&X-API-KEY=ijEJ5wN8dyLCmTgXMDeqmocprI-gcCy4PMQz0a7cFYo
  `;

  return (
    <div className="pre-content">
      <PreContentComponent spanText="User Guide" />
      <HeroBanner page="userGuide" />
      <div
        className="views-element-container"
        id="block-views-block-homepage-hero-block-1"
      >
        <div className="block-views-block-homepage-hero-block-1">
          <div className="js-view-dom-id-0176fc946b1c2df0b5f911bd6146810094db8e6897febb20c3aa9c8fac3db926">
            <div className="container">
              <div className="row" id="user_guide">
                <div className="col-sm-12 col-md-6 col-lg-8">
                  <div className="row">
                    <h3>API User Guide (PDF)</h3>
                    <p>
                      {' '}
                      <a href="pdf/dol-api-user-guide.pdf">
                        <img
                          src="/images/PDF_file_icon.png"
                          className="pdf"
                          alt="Download API User Guide"
                        />
                        Download API User Guide
                      </a>{' '}
                      (0.4 MB)
                    </p>

                    <div id="toc_outer_container" />

                    <h3 className="toc_anchor">Overview‌</h3>
                    <p>
                      This guide offers an overview of the Department of
                      Labor’s Open Data Application Programming Interface (DOL
                      API), along with guidance and instructions to assist you
                      in accessing our metadata and data.
                    </p>

                    <h3 className="toc_anchor">What is the DOL API?</h3>
                    <p>
                      The DOL API is a web service that provides on-demand
                      access to machine readable metadata and data.
                    </p><p>Using our API offers many benefits, including:</p>
                    <ul>
                      <li>
                        <b>Customizable response.</b> You can request subsets of
                        data you need instead of the entire dataset.
                      </li>

                      <li>
                        <b>Automation.</b> You can reuse your customized data
                        requests, which allows for more productive workflows.
                      </li>

                      <li>
                        <b>Live and updated data.</b> You can be confident that
                        every API request you make will always return the latest
                        data.
                      </li>

                      <li>
                        <b>Programming-language agnostic.</b> You can connect to
                        the API through your preferred statistical software
                        programs including Python, R, and SAS.
                      </li>
                    </ul>

                    <h3 className="toc_anchor">Getting Started</h3>

                    <p>
                      To use our APIs, you need to{' '}
                      <a href="/registration"> register for an account</a> and complete our questionnaire to get an API key. Once you register, there may be a slight delay while the
                      system incorporates your credentials, and you may need to
                      wait for a few minutes and/or refresh your screen before
                      an API key is generated in your account. Your API key will
                      look like a mixture of numbers, letters, and special
                      characters. You can create additional keys (up to 5) if
                      you need to use them for different purposes, client
                      applications, or automation.
                    </p>

                    <img
                      src={require('../../images/api-keys.png')}
                      alt="API Authorize Button"
                      className="standalone_image"
                      style={{ width: '90%' }}
                    />

                    <p>
                      Your API key is what grants you access to the DOL API and
                      is a required parameter for all metadata and data
                      requests. You can always{' '}
                      <a href="/api-keys">find, manage, and create new keys</a>{' '}
                      after signing into the open data portal. The sharing of
                      your API key(s) with others, including on public code
                      repositories or other public forums is strictly prohibited
                      and may result in the termination of your API service.{' '}
                    </p>

                    <h3 className="toc_anchor">Finding Datasets</h3>

                    <p>
                      The U.S. Department of Labor publishes a large amount of
                      data, including statistical, enforcement, legal, policy,
                      administrative, and financial data. The Data Catalog is an
                      inventory of datasets that are currently available for
                      query through the API. You can view the full list of
                      available datasets in the{' '}
                      <a href="/datasets">Data Catalog</a>. The catalog can be
                      sorted by dataset name, or filtered by category, tag, or
                      agency using the left sidebar.
                    </p>

                    <p>
                      <b>Example:</b> Filter on accidents tag
                    </p>

                    <img
                      src="/images/data-catalog-filter.png"
                      alt="Data Catalog screen"
                      className="standalone_image"
                    />

                    <p>
                      To programmatically browse our Data Catalog in JSON
                      format, use our Datasets API:{' '}
                      <a href="https://apiprod.dol.gov/v4/datasets">
                        https://apiprod.dol.gov/v4/datasets
                      </a>
                      . No api key is required to access this endpoint. Code
                      examples for accessing this endpoint and using the
                      resulting data are also available on our{' '}
                      <a href="/api-examples">API Examples</a> page.
                    </p>

                    <h3 className="toc_anchor">Discovering Metadata</h3>

                    <p>
                      Every dataset in the API is comprehensively described by
                      our <a href="/metadata">metadata</a>. Metadata can help
                      you better understand the properties and characteristics
                      of the variables within a dataset, inform whether the data
                      will be potentially useful for specific purposes prior to
                      access, and provide considerable benefit in automating the
                      processing of the resulting data.
                    </p>

                    <p>The Metadata API can be accessed using this template:</p>
                    <p>
                      JSON Format: https://apiprod.dol.gov/v4/get/
                      <b>&lt;agency&gt;</b>/<b>&lt;endpoint&gt;</b>
                      /json/metadata?X-API-KEY=<b>&lt;api_key&gt;</b>
                      <br />
                      CSV Format: https://apiprod.dol.gov/v4/get/
                      <b>&lt;agency&gt;</b>/<b>&lt;endpoint&gt;</b>
                      /csv/metadata?X-API-KEY=<b>&lt;api_key&gt;</b>
                    </p>

                    <p>
                      To retrieve metadata, paste a link into a web browser (or use your preferred software) and replace &lt;agency&gt; with an <i>agency name abbreviation</i> and &lt;endpoint&gt; with
                      an <i>api_url</i> from the <a href="https://apiprod.dol.gov/v4/datasets">Datasets API</a>. Also, substitute &lt;api_key&gt; with your unique API key. You will need
                      to <a href="/registration">register or sign-in</a> to retrieve your <a href="/api-keys">API key</a>.
                    </p>
                    <p>
                      Common use cases for accessing metadata and code examples
                      can be found on the{' '}
                      <a href="/api-examples">API Examples</a> page.
                    </p>

                    <h3>Building an API query</h3>
                    <p>
                      To start building API queries, we recommend using our API
                      Query Builder Tool which is located towards the bottom of
                      every dataset page you navigate to from the{' '}
                      <a href="/datasets">Data Catalog</a>. The API Query
                      Builder will provide you with basic templates for making
                      API calls for each endpoint and allows you to
                      interactively select which fields and records you want,
                      choose how records are formatted and sorted, and generate
                      a proper request URL and curl command that you can use to
                      get your desired data.
                    </p>
                    <h3>Parameters</h3>

                    <p>
                      Parameters are options you can add to an API call that
                      allow you to customize your data request: what subsets of
                      records are returned; how many records are returned, which
                      variables are included; and how the data are formatted and
                      sorted. Listed below are the parameters that can be
                      accepted in a request.
                    </p>
                    <br />
                    <ul>
                      <li>
                        <b>X-API-KEY</b> (string,{' '}
                        <span className="red">required</span>
                        ): User’s API key for authentication.
                      </li>
                      <p>
                        Your key is automatically populated by the API Query
                        Builder if you are signed into your open data portal
                        account. The color of the “Authorize” button indicates
                        whether your API key is in use. Red indicates that you
                        are not signed into your account and will not be able to
                        request data. Green indicates that you are signed into
                        your account and will be able to request data.
                      </p>
                      <img
                        src={require('../../images/parameters.png')}
                        alt="API Authorize Button"
                        className="standalone_image"
                        style={{ width: '90%' }}
                      />

                      <li>
                        <b>format</b> (string,{' '}
                        <span className="red">required</span>
                        ): The data format returned by the API.
                        <ul>
                          <li>Options: "json", "xml", "csv"</li>
                          <li>Default: json</li>
                        </ul>
                      </li>
                      <li>
                        <b>limit</b> (integer): The maximum number of records to
                        be returned.
                        <ul>
                          <li>
                            Options: You can receive up to 5 MB of data or 10,000 records per request, whichever limit you hit first.
                          </li>
                          <li>Default: 10</li>
                        </ul>
                      </li>
                      <li>
                        <b>offset</b> (integer): The number of records to skip
                        from the top of a dataset. This parameter can be used in
                        conjunction with the limit parameter when you are
                        requesting more than the maximum record limit for a
                        dataset. For example, if you need 20,000 records you
                        would get the first chunk of 10,000 records by setting
                        the limit to 10000 and offset to 0, and the second chunk
                        of 10,000 records by setting the limit to 10000 and
                        offset to 10000.
                        <ul>
                          <li>Options: Positive integer.</li>
                          <li>Default: 0</li>
                        </ul>
                      </li>
                      <li>
                        <b>fields</b> (string): The specific fields you want to
                        select from a dataset. For example, you would use
                        “case_identifier,naics_code,back_wages” to only return
                        these 3 fields from a dataset.
                        <ul>
                          <li>
                            Options: Comma separated string of dataset field
                            names.
                          </li>
                          <li>
                            Default: all variables are included in the output
                          </li>
                        </ul>
                      </li>
                      <li>
                        <b>sort</b> (string): The sort direction that you want
                        applied to the records of a dataset.
                        <ul>
                          <li>
                            Options: “asc” for ascending or “desc” for
                            descending.
                          </li>
                        </ul>
                      </li>

                      <li>
                        <b>sort_by</b> (string): The field name you want to sort
                        by.
                        <ul>
                          <li>Options: field name</li>
                        </ul>
                      </li>

                      <li>
                        <b>filter_object</b> (string): The conditional column
                        filters to apply to a dataset.
                        <ul><li>
                          Options: A JSON formatted
                          string that specifies the values for <i>field</i>, <i>operator</i>,
                          and <i>value</i>. The operators we support are eq (equals), neq
                          (not equals), gt (greater than), lt (less than), in,
                          not_in, and like.
                            </li>
                        </ul>
                      </li>
                    </ul>

                    <p>
                      Additional information on filtering datasets can be found
                      in the Conditional Column Filtering section.
                    </p>

                    <h3 className="toc_anchor">
                      Executing and Debugging Queries
                    </h3>

                    <p>
                      Once parameters have been populated into the API Query
                      Builder, you can proceed with clicking the “Execute”
                      button to receive your response. The API Query Builder
                      will display the request URL as well as the server
                      response.
                    </p>
                    <img
                      src="/images/execute-button.png"
                      alt="Execute Button"
                      className="standalone_image"
                    />
                    <p>(a) Request URL</p>

                    <p>
                      The request URL is what you will need to use external
                      software and applications to pull data in from the API.
                      You can also execute the URL within a browser to verify
                      the call or view the results.{' '}
                    </p>
                    <img
                      src={require('../../images/request-url.png')}
                      alt="API Authorize Button"
                      className="standalone_image"
                      style={{ width: '90%' }}
                    />
                    <br />
                    <br />
                    <p>(b) Server response</p>
                    <p>
                      If everything is successful, you will see data in the
                      response body as well as the code “200” in the server
                      response. You will also have the option to download the
                      response through a blue button in the lower right of the
                      Responses box.
                    </p>

                    <img
                      src={require('../../images/server-response.png')}
                      alt="API Query Image"
                      className="standalone_image"
                    />

                    <p>
                      If your API query fails and you get a server response code
                      other than “200”, verify that your api key is in use by
                      checking for a green “Authorize button” and check your
                      output and parameters for any issues.
                    </p>
                    {/* <ul>
                      <li>
                        <b>200</b> – OK. Request has been processed successfully
                        in the server.
                      </li>
                      <li>
                        <b>400</b> – Bad request. This indicates the request was
                        unsuccessful due to invalid syntax. The response will
                        provide a description of the error that will help debug
                        the syntax issue.
                      </li>
                      <li>
                        <b>500</b> – Internal server error. This indicates that
                        something went wrong on the server. Please contact us
                        for more details.
                      </li>
                    </ul> */}

                    <h3>Conditional Filtering</h3>
                    <p>
                      Next, we are going to cover dataset filtering in more
                      detail.{' '}
                    </p>
                    <p>
                      The <b>filter_object</b> parameter allows you to subset a dataset
                      based on conditions for one or more fields. To execute
                      this in a query, it requires a JSON formatted string that
                      has three key-value pairs.
                    </p>
                    <ul>
                      <li>
                        <i>field</i> – The field name the filter will be applied
                        on.
                      </li>
                      <li>
                        <i>operator</i> – The type of equality operation to
                        perform.
                      </li>
                      <li>
                        <i>value</i> – The conditional value to apply to the
                        field.
                      </li>
                    </ul>

                    <h3>Examples</h3>
                    <p>
                      For the following filtering examples, consider the dataset
                      below:
                    </p>

                    <table
                      className="regular table_center"
                      id="BLStable_2021_3_17_11_20"
                      style={{ width: '95%' }}
                    >
                      <thead className="table_center">
                        <tr>
                          <th
                            scope="col"
                            rowSpan="1"
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '80px',
                              textAlign: 'center',
                            }}
                          >
                            <p>Industry</p>
                          </th>
                          <th
                            scope="col"
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '80px',
                              textAlign: 'center',
                            }}
                          >
                            <p>Year</p>
                          </th>
                          <th
                            scope="col"
                            style={{
                              verticalAlign: 'middle',
                              minWidth: '80px',
                              textAlign: 'center',
                            }}
                          >
                            <p>Value</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table_center">
                        <tr>
                          <th scope="row">
                            <p>A</p>
                          </th>
                          <td>2021</td>
                          <td>301</td>
                        </tr>
                        <tr className="greenbar">
                          <th scope="row">
                            <p>A</p>
                          </th>
                          <td>2022</td>
                          <td>456</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <p>B</p>
                          </th>
                          <td>2019</td>
                          <td>983</td>
                        </tr>
                        <tr className="greenbar">
                          <th scope="row">
                            <p>B</p>
                          </th>
                          <td>2020</td>
                          <td>475</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <p>B</p>
                          </th>
                          <td>2021</td>
                          <td>159</td>
                        </tr>
                        <tr className="greenbar">
                          <th scope="row">
                            <p>C</p>
                          </th>
                          <td>2021</td>
                          <td>979</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <p>C</p>
                          </th>
                          <td>2022</td>
                          <td>459</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <p>AB</p>
                          </th>
                          <td>2021</td>
                          <td>1200</td>
                        </tr>
                        <tr className="greenbar">
                          <th scope="row">
                            <p>AB</p>
                          </th>
                          <td>2022</td>
                          <td>1489</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <p>AC</p>
                          </th>
                          <td>2020</td>
                          <td>854</td>
                        </tr>
                        <tr className="greenbar">
                          <th scope="row">
                            <p>AC</p>
                          </th>
                          <td>2021</td>
                          <td>533</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <p>AC</p>
                          </th>
                          <td>2022</td>
                          <td>89</td>
                        </tr>
                      </tbody>
                      <tfoot
                        style={{ display: 'none' }}
                        id="BLStable_2021_3_17_11_20_footnotes"
                      >
                        <tr className="footnotes">
                          <td className="footnotes" />
                        </tr>
                      </tfoot>
                    </table>

                    <h3>Single condition filter</h3>

                    <p>
                      Example (equals operator): Filter records where industry
                      is equal to “B”.
                    </p>

                    <pre className="prettyprint">{singleColumnFilter}</pre>
                    <p>
                      Example (not equals operator): Filter records where
                      industry is not equal to “B”.
                    </p>
                    <pre className="prettyprint">{singleColumnFilter2}</pre>
                    <p>
                      Example (in operator): Filter records where industry is in
                      “A”, “B”, or “C”.
                    </p>
                    <pre className="prettyprint">{singleColumnFilter3}</pre>
                    <p>
                      Example (not_in operator): Filter records where industry
                      is not in “A”, “B”, or “C”.
                    </p>
                    <pre className="prettyprint">{singleColumnFilter4}</pre>
                    <p>
                      Example (like operator): Filter records where industry is
                      like “A”.
                    </p>
                    <pre className="prettyprint">{singleColumnFilter5}</pre>
                    <h3>Multiple condition filter</h3>
                    <p>
                      Example (and operator): Filter records where industry is
                      equal to “B” and year is equal to “2020”.
                    </p>
                    <pre className="prettyprint">{multipleColumnFilter}</pre>
                    <h3>Single variable, multiple condition filter</h3>
                    <p>
                      Example (or operator): Filter records where value is less
                      than 500 or greater than 999.{' '}
                    </p>
                    <pre className="prettyprint">
                      {singleColumnMultipleFilter}
                    </pre>
                    <h3>Multiple variable, multiple condition filter</h3>

                    <p>
                      Example (and/or operators): Filter records where year is
                      equal to “2021” and where industry is equal to either “A”
                      or “C".
                    </p>

                    <pre className="prettyprint">
                      {multipleColumnMultipleFilter}
                    </pre>

                    <h3>Final Query</h3>

                    <p>
                      The final API query, which is using filters to produce a
                      specific subset of records from an API data source will
                      look something like this:{' '}
                    </p>

                    <pre className="prettyprint">{finalQuery}</pre>

                    <h3>All API requests will follow a similar structure:</h3>

                    <pre className="prettyprint">
                      &lt;API server address&gt;/get/&lt;agency name&gt;/&lt;api
                      endpoint
                      url&gt;/&lt;format&gt;?&lt;parameter&gt;=&lt;value&gt;&&lt;parameter&gt;=&lt;value&gt;&...
                    </pre>
                    <h3>Help and Contact Us</h3>
                    <p>
                      If you need help using the API or have additional
                      questions, try out the resources that we make available:
                    </p>
                    <ul>
                      <li>
                        {' '}
                        <a href="/faq">
                          Frequently Asked Questions
                        </a>{' '}
                      </li>
                      <li>
                        {' '}
                        <a href="/api-examples">
                          API Examples
                        </a>{' '}
                      </li>
                      <li>
                        {' '}
                        <a href="/video-tutorials">
                          Videos Tutorials
                        </a>{' '}
                      </li>
                    </ul>
                    <p>
                      For further assistance or to provide feedback on our Open
                      Data Portal and API, <a href="/contact-us">Contact Us</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserGuide;
