/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from 'react';

function FooterComponent() {
  return (
    <footer className="usa-footer usa-footer--big" role="contentinfo">
      <div className="usa-footer__return-to-top container">
        {' '}
        <a href="#" alt="scroll to top" className="footer-scroll-to-top">
          <span>Scroll to Top</span>{' '}
          <i className="fa fa-arrow-circle-o-up" aria-hidden="true" />
        </a>{' '}
      </div>
      <section data-testid="dolFooterNav" className="dol-footer-nav">
        <div>
          <div className="panel">
            <ul
              data-testid="dolFooterNavMenu"
              className="clearfix dol-footer-nav-menu container"
              style={{ listStyleType: 'none' }}
            >
              {/* Update any designated HTML links and text links Here  */}
              <li data-testid="dolFooterNavLi">
                {' '}
                <a
                  href="https://www.dol.gov/general/dol-agencies"
                  className="dol-footer-nav-link"
                  data-testid="dolFooterNavFaq"
                >
                  Agencies
                </a>{' '}
              </li>
              <li data-testid="dolFooterNavLi">
                {' '}
                <a
                  href="https://www.dol.gov/general/forms"
                  className="dol-footer-nav-link"
                  data-testid="dolFooterNavFaq"
                >
                  Forms
                </a>{' '}
              </li>
              <li data-testid="dolFooterNavLi">
                {' '}
                <a
                  href="https://www.dol.gov/guidance"
                  className="dol-footer-nav-link"
                  data-testid="dolFooterNavFaq"
                >
                  Guidance Search
                </a>{' '}
              </li>
              <li data-testid="dolFooterNavLi">
                {' '}
                <a
                  href="https://webapps.dol.gov/dolfaq/"
                  className="dol-footer-nav-link"
                  data-testid="dolFooterNavFaq"
                >
                  FAQ
                </a>{' '}
              </li>
              <li data-testid="dolFooterNavLi">
                {' '}
                <a
                  href="https://www.dol.gov/general/aboutdol"
                  className="dol-footer-nav-link"
                  data-testid="dolFooterNavFaq"
                >
                  About DOL
                </a>{' '}
              </li>
              <li data-testid="dolFooterNavLi">
                {' '}
                <a
                  href="https://www.dol.gov/newsroom"
                  className="dol-footer-nav-link"
                  data-testid="dolFooterNavNews"
                >
                  News
                </a>{' '}
              </li>
              <li data-testid="dolFooterNavLi">
                {' '}
                <a
                  href="https://www.dol.gov/general/contact"
                  className="dol-footer-nav-link"
                  data-testid="dolFooterNavContactUs"
                >
                  Contact Us
                </a>{' '}
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* START primary DOL Footer  */}
      <div className="dol-footer-primary usa-footer__primary-section">
        <div className="grid-container container dol-footer__full-menu">
          <div className="width-inner-padding grid-row grid-gap">
            <div data-testid="dolFooterAddress" className="footer-address">
              <div className="grid-row">
                <div className="dol-footer-seal">
                  <div className="dol-seal">
                    {' '}
                    <a
                      data-testid="dolFooterSeal"
                      href="https://www.dol.gov"
                      title="DOL Seal"
                      tabIndex={-1}
                    >
                      {' '}
                      <img
                        data-testid="dolFooterSealImage"
                        alt="U.S. Department of Labor Seal"
                        src="https://www.dol.gov/drupal-assets/themes/opa_theme/img/DOL-MasterLogo_BLUE.svg"
                      />{' '}
                    </a>
                  </div>
                  <div className="footer-seal-block">
                    <div className="footer-seal-block">
                      <div
                        data-testid="dolFooterContactBlock"
                        className="contact-block"
                      >
                        <h5>
                          <div
                            style={{ fontWeight: 'bold', marginBottom: '1em' }}
                            data-testid="dolFooterContactName"
                          >
                            U.S. DEPARTMENT OF LABOR
                          </div>
                        </h5>
                        <div className="field field--name-field-agency-address field--type-text-with-summary field--label-hidden clearfix">
                          <div className="address">
                            <p className="address-block">
                              200 Constitution Ave NW <br />
                              Washington, DC 20210 <br />
                              <a
                                href="tel:1-866-487-2365"
                                onMouseDown={() => "_sendEvent('Telephone Clicks','1-866-487-2365','',0);"}
                              >
                                <span>1-866-4-USA-DOL</span>
                                <br />
                                <span>1-866-487-2365</span>
                              </a>
                              <br />
                              <a href="https://www.dol.gov">www.dol.gov </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer_menu">
              <nav className="usa-footer__nav footer__nav">
                <div className="grid-row">
                  {/* Begin Footer Second */}
                  <div
                    data-testid="dolFooterMenu3"
                    id="block-dolfootermenu-3"
                    className="footer_menu-block"
                  >
                    <section
                      id="block-dolfootermenu-3"
                      className="usa-footer__primary-content usa-footer__primary-content--collapsible dol-footer__primary-content dol-footer__primary-content--collapsible hidden"
                    >
                      <h4
                        data-testid="federalGovernmentHeader"
                        className="usa-footer__primary-link"
                      >
                        Federal Government
                        <i className="far fa-plus-square" />
                      </h4>
                      <br />
                      <ul
                        style={{ marginLeft: '0px' }}
                        className="clearfix usa-list usa-list--unstyled"
                      >
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            style={{ whiteSpace: 'nowrap' }}
                            data-testid="whiteHouseLink"
                            href="https://www.whitehouse.gov/"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            White House{' '}
                            <svg
                              aria-hidden="true"
                              data-prefix="fab"
                              data-icon="facebook-f"
                              className="svg-inline--fa fa-facebook-f fa-w-9"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 80 40"
                            >
                              <title>(link is external)</title>
                              <path
                                stroke="#005EA2"
                                strokeWidth="4"
                                d="M48 26c-1.1 0-2 0.9-2 2v26H10V18h26c1.1 0 2-0.9 2-2s-0.9-2-2-2H8c-1.1 0-2 0.9-2 2v40c0 1.1 0.9 2 2 2h40c1.1 0 2-0.9 2-2V28C50 26.9 49.1 26 48 26z"
                              />
                              <path d="M56 6H44c-1.1 0-2 0.9-2 2s0.9 2 2 2h7.2L30.6 30.6c-0.8 0.8-0.8 2 0 2.8C31 33.8 31.5 34 32 34s1-0.2 1.4-0.6L54 12.8V20c0 1.1 0.9 2 2 2s2-0.9 2-2V8C58 6.9 57.1 6 56 6z" />
                            </svg>
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolStormRecoveryLink"
                            href="https://www.dol.gov/coronavirus"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            Coronavirus Resources
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolDisasterRecoveryLink"
                            href="https://www.dol.gov/general/disasterrecovery"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            Disaster Recovery Assistance
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            style={{ whiteSpace: 'nowrap' }}
                            data-testid="dolDisasterAssistanceLink"
                            href="https://www.disasterassistance.gov/"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            DisasterAssistance.gov{' '}
                            <svg
                              aria-hidden="true"
                              data-prefix="fab"
                              data-icon="facebook-f"
                              className="svg-inline--fa fa-facebook-f fa-w-9"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 80 40"
                            >
                              <title>(link is external)</title>
                              <path
                                stroke="#005EA2"
                                strokeWidth="4"
                                d="M48 26c-1.1 0-2 0.9-2 2v26H10V18h26c1.1 0 2-0.9 2-2s-0.9-2-2-2H8c-1.1 0-2 0.9-2 2v40c0 1.1 0.9 2 2 2h40c1.1 0 2-0.9 2-2V28C50 26.9 49.1 26 48 26z"
                              />
                              <path d="M56 6H44c-1.1 0-2 0.9-2 2s0.9 2 2 2h7.2L30.6 30.6c-0.8 0.8-0.8 2 0 2.8C31 33.8 31.5 34 32 34s1-0.2 1.4-0.6L54 12.8V20c0 1.1 0.9 2 2 2s2-0.9 2-2V8C58 6.9 57.1 6 56 6z" />
                            </svg>
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            style={{ whiteSpace: 'nowrap' }}
                            data-testid="linkUSAGov"
                            href="https://www.usa.gov/"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            USA.gov{' '}
                            <svg
                              aria-hidden="true"
                              data-prefix="fab"
                              data-icon="facebook-f"
                              className="svg-inline--fa fa-facebook-f fa-w-9"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 80 40"
                            >
                              <title>(link is external)</title>
                              <path
                                stroke="#005EA2"
                                strokeWidth="4"
                                d="M48 26c-1.1 0-2 0.9-2 2v26H10V18h26c1.1 0 2-0.9 2-2s-0.9-2-2-2H8c-1.1 0-2 0.9-2 2v40c0 1.1 0.9 2 2 2h40c1.1 0 2-0.9 2-2V28C50 26.9 49.1 26 48 26z"
                              />
                              <path d="M56 6H44c-1.1 0-2 0.9-2 2s0.9 2 2 2h7.2L30.6 30.6c-0.8 0.8-0.8 2 0 2.8C31 33.8 31.5 34 32 34s1-0.2 1.4-0.6L54 12.8V20c0 1.1 0.9 2 2 2s2-0.9 2-2V8C58 6.9 57.1 6 56 6z" />
                            </svg>
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolNoFearActLink"
                            href="https://www.dol.gov/agencies/oasam/centers-offices/civil-rights-center/cummings-antidiscrimination-act-2020"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            Notification of EEO Violations
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolNoFearActLink"
                            href="https://www.dol.gov/agencies/oasam/centers-offices/civil-rights-center/resports/notification-and-federal-employee-antidiscrimination-retaliation-act-of-2002"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            No Fear Act Data
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="oscUSALink"
                            href="https://osc.gov/"
                            style={{ whiteSpace: 'nowrap' }}
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            U.S. Office of Special Counsel{' '}
                            <svg
                              aria-hidden="true"
                              data-prefix="fab"
                              data-icon="facebook-f"
                              className="svg-inline--fa fa-facebook-f fa-w-9"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 80 40"
                            >
                              <title>(link is external)</title>
                              <path
                                stroke="#005EA2"
                                strokeWidth="4"
                                d="M48 26c-1.1 0-2 0.9-2 2v26H10V18h26c1.1 0 2-0.9 2-2s-0.9-2-2-2H8c-1.1 0-2 0.9-2 2v40c0 1.1 0.9 2 2 2h40c1.1 0 2-0.9 2-2V28C50 26.9 49.1 26 48 26z"
                              />
                              <path d="M56 6H44c-1.1 0-2 0.9-2 2s0.9 2 2 2h7.2L30.6 30.6c-0.8 0.8-0.8 2 0 2.8C31 33.8 31.5 34 32 34s1-0.2 1.4-0.6L54 12.8V20c0 1.1 0.9 2 2 2s2-0.9 2-2V8C58 6.9 57.1 6 56 6z" />
                            </svg>
                          </a>{' '}
                        </li>
                      </ul>
                    </section>
                  </div>
                  <div
                    data-testid="dolFooterMenu4"
                    id="block-dolfootermenu-4"
                    className="footer_menu-block"
                  >
                    <section
                      id="block-dolfootermenu-4"
                      className="usa-footer__primary-content usa-footer__primary-content--collapsible dol-footer__primary-content dol-footer__primary-content--collapsible"
                    >
                      <h4
                        data-testid="laborDepartmentHeader"
                        className="usa-footer__primary-link"
                      >
                        Labor Department
                        <i className="far fa-plus-square" />
                      </h4>
                      <br />
                      <ul
                        style={{ marginLeft: '0px' }}
                        // className="block-dolfootermenu-4"
                        className="clearfix usa-list usa-list--unstyled"
                      >
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="aboutDOL"
                            href="https://www.dol.gov/general/aboutdol"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            About DOL
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolEspanol"
                            href="https://www.dol.gov/guidance"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            Guidance Search
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolEspanol"
                            href="https://www.dol.gov/general/topic/spanish-speakingtopic"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            Español
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolOfficeOfInspectorGeneral"
                            href="https://www.oig.dol.gov/"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            Office of Inspector General
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            style={{ whiteSpace: 'nowrap' }}
                            data-testid="dolSubscribeToNewsLetter"
                            href="https://public.govdelivery.com/accounts/USDOL/subscriber/new?topic_id=USDOL_167"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            Subscribe to the DOL Newsletter{' '}
                            <svg
                              aria-hidden="true"
                              data-prefix="fab"
                              data-icon="facebook-f"
                              className="svg-inline--fa fa-facebook-f fa-w-9"
                              role="img"
                              style={{ color: 'red' }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 80 40"
                            >
                              <title>(link is external)</title>
                              <path
                                stroke="#005EA2"
                                strokeWidth="4"
                                d="M48 26c-1.1 0-2 0.9-2 2v26H10V18h26c1.1 0 2-0.9 2-2s-0.9-2-2-2H8c-1.1 0-2 0.9-2 2v40c0 1.1 0.9 2 2 2h40c1.1 0 2-0.9 2-2V28C50 26.9 49.1 26 48 26z"
                              />
                              <path d="M56 6H44c-1.1 0-2 0.9-2 2s0.9 2 2 2h7.2L30.6 30.6c-0.8 0.8-0.8 2 0 2.8C31 33.8 31.5 34 32 34s1-0.2 1.4-0.6L54 12.8V20c0 1.1 0.9 2 2 2s2-0.9 2-2V8C58 6.9 57.1 6 56 6z" />
                            </svg>
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolReadNewsLetter"
                            href="https://www.dol.gov/newsroom/newsletter"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            Read the DOL Newsletter
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolEmergencyAccountabilityStatus"
                            href="https://www.dol.gov/general/easl"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            Emergency Accountability Status Link
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolIndex"
                            href="https://www.dol.gov/general/siteindex"
                            className="usa-footer__secondary-link dol-footer__secondary-link"
                          >
                            A to Z Index
                          </a>{' '}
                        </li>
                      </ul>
                    </section>
                  </div>
                  <div
                    data-testid="dolFooterMenu5"
                    className="mobile-lg:grid-col-4 desktop:grid-col-4 footer_menu-block"
                  >
                    <section className="usa-footer__primary-content usa-footer__primary-content--collapsible dol-footer__primary-content dol-footer__primary-content--collapsible">
                      <h4
                        data-testid="aboutTheSite"
                        className="usa-footer__primary-link"
                      >
                        About the Site
                        <i className="far fa-plus-square" />
                      </h4>
                      <br />
                      <ul
                        style={{ marginLeft: '0px' }}
                        className="clearfix usa-list usa-list--unstyled"
                      >
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolFOIA"
                            href="https://www.dol.gov/general/foia"
                            className="usa-footer__secondary-link dol-footer__secondary-link footer-secondary-nav-link"
                          >
                            Freedom of Information Act
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolDisclaimer"
                            href="https://www.dol.gov/general/privacynotice"
                            className="usa-footer__secondary-link dol-footer__secondary-link footer-secondary-nav-link"
                          >
                            Privacy and Security Statement
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolDisclaimer"
                            href="https://www.dol.gov/general/disclaim"
                            className="usa-footer__secondary-link dol-footer__secondary-link footer-secondary-nav-link"
                          >
                            Disclaimers
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolDisclaimer"
                            href="https://www.dol.gov/general/aboutdol/website-policies"
                            className="usa-footer__secondary-link dol-footer__secondary-link footer-secondary-nav-link"
                          >
                            Important Website Notices
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolPlugins"
                            href="https://www.dol.gov/general/aboutdol/file-formats"
                            className="usa-footer__secondary-link dol-footer__secondary-link footer-secondary-nav-link"
                          >
                            Plug-Ins Used on DOL.gov
                          </a>{' '}
                        </li>
                        <li className="usa-footer__secondary-link dol-footer__secondary-link">
                          {' '}
                          <a
                            data-testid="dolAccessibilityStatement"
                            href="https://www.dol.gov/general/aboutdol/accessibility"
                            className="usa-footer__secondary-link dol-footer__secondary-link footer-secondary-nav-link"
                          >
                            Accessibility Statement
                          </a>{' '}
                        </li>
                      </ul>
                    </section>
                  </div>
                  {/* End Footer */}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* Begin Sub Footer */}
      <section
        data-testid="dolFooterMenuSocialMedia"
        className="dol-footer-secondary"
      >
        <div className="width">
          <div className="width-inner-padding container">
            <div className="usa-grid-full dol-footer-secondary">
              <div className="dol-footer-secondary-container">
                <div className="usa-width-one-half social-media-block">
                  <p>Connect With DOL</p>
                  <div className="social-media-icons">
                    {' '}
                    <a
                      style={{ color: 'black' }}
                      data-testid="dolSocialMediaFaceBook"
                      className="social-media-icon"
                      href="https://www.facebook.com/departmentoflabor"
                      title="Department of Labor Facebook"
                    >
                      {/* ?xml version="1.0" encoding="utf-8"? */}
                      <svg
                        aria-hidden="true"
                        data-prefix="fab"
                        data-icon="facebook-f"
                        className="svg-inline--fa fa-facebook-f fa-w-9"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 264 512"
                      >
                        <desc>Facebook Icon</desc>
                        <path
                          fill="currentColor"
                          d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"
                        />
                      </svg>
                      <span className="usa-sr-only">Facebook</span>{' '}
                    </a>{' '}
                    <a
                      data-testid="dolSocialMediaTwitter"
                      className="social-media-icon"
                      href="https://x.com/USDOL"
                      title="Department of Labor X"
                    >
                      {/* ?xml version="1.0" encoding="utf-8"? */}
                      <svg
                        aria-hidden="true"
                        data-prefix="fab"
                        data-icon="x"
                        className="svg-inline--fa fa-twitter fa-w-16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <desc>Twitter Icon</desc>
                        <path
                          fill="currentColor"
                          d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                        />
                      </svg>
                      <span className="usa-sr-only">X</span>{' '}
                    </a>{' '}
                    <a
                      data-testid="dolSocialMediaInstagram"
                      className="social-media-icon"
                      href="https://www.instagram.com/USDOL/"
                      title="Department of Labor Instagram"
                    >
                      {/* ?xml version="1.0" encoding="utf-8"? */}
                      <svg
                        aria-hidden="true"
                        data-prefix="fab"
                        data-icon="instagram"
                        className="svg-inline--fa fa-instagram fa-w-14"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <desc>Instagram Icon</desc>
                        <path
                          fill="currentColor"
                          d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                        />
                      </svg>
                      <span className="usa-sr-only">Instagram</span>{' '}
                    </a>{' '}
                    <a
                      data-testid="dolSocialMediaYouTube"
                      className="social-media-icon"
                      href="https://www.threads.net/@usdol"
                      title="Threads for the United States Department of Labor"
                    >
                      {/* ?xml version="1.0" encoding="utf-8"? */}
                      <svg
                        aria-hidden="true"
                        data-prefix="fab"
                        data-icon="threads"
                        className="svg-inline--fa fa-square-threads fa-2xl"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 -100 576 512"
                      >
                        <desc>Threads</desc>
                        <path
                          fill="currentColor"
                          d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM294.2 244.3c19.5 9.3 33.7 23.5 41.2 40.9c10.4 24.3 11.4 63.9-20.2 95.4c-24.2 24.1-53.5 35-95.1 35.3h-.2c-46.8-.3-82.8-16.1-106.9-46.8C91.5 341.8 80.4 303.7 80 256v-.1-.1c.4-47.7 11.5-85.7 33-113.1c24.2-30.7 60.2-46.5 106.9-46.8h.2c46.9 .3 83.3 16 108.2 46.6c12.3 15.1 21.3 33.3 27 54.4l-26.9 7.2c-4.7-17.2-11.9-31.9-21.4-43.6c-19.4-23.9-48.7-36.1-87-36.4c-38 .3-66.8 12.5-85.5 36.2c-17.5 22.3-26.6 54.4-26.9 95.5c.3 41.1 9.4 73.3 26.9 95.5c18.7 23.8 47.4 36 85.5 36.2c34.3-.3 56.9-8.4 75.8-27.3c21.5-21.5 21.1-47.9 14.2-64c-4-9.4-11.4-17.3-21.3-23.3c-2.4 18-7.9 32.2-16.5 43.2c-11.4 14.5-27.7 22.4-48.4 23.5c-15.7 .9-30.8-2.9-42.6-10.7c-13.9-9.2-22-23.2-22.9-39.5c-1.7-32.2 23.8-55.3 63.5-57.6c14.1-.8 27.3-.2 39.5 1.9c-1.6-9.9-4.9-17.7-9.8-23.4c-6.7-7.8-17.1-11.8-30.8-11.9h-.4c-11 0-26 3.1-35.6 17.6l-23-15.8c12.8-19.4 33.6-30.1 58.5-30.1h.6c41.8 .3 66.6 26.3 69.1 71.8c1.4 .6 2.8 1.2 4.2 1.9l.1 .5zm-71.8 67.5c17-.9 36.4-7.6 39.7-48.8c-8.8-1.9-18.6-2.9-29-2.9c-3.2 0-6.4 .1-9.6 .3c-28.6 1.6-38.1 15.5-37.4 27.9c.9 16.7 19 24.5 36.4 23.6l-.1-.1z"
                        />
                      </svg>
                      <span className="usa-sr-only">Threads</span>{' '}
                    </a>{' '}
                    <a
                      data-testid="dolSocialMediaYouTube"
                      className="social-media-icon"
                      href="https://www.linkedin.com/company/u-s-department-of-labor/"
                      title="LinkedIn for the United States Department of Labor"
                    >
                      {/* ?xml version="1.0" encoding="utf-8"? */}
                      <svg
                        aria-hidden="true"
                        data-prefix="fab"
                        data-icon="LinkedIn"
                        className="svg-inline--fa fa-linkedin fa-8x8"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 -100 576 512"
                      >
                        <desc>LinkedIn</desc>
                        <path
                          fill="currentColor"
                          d="M150.65,100.682c0,27.992-22.508,50.683-50.273,50.683c-27.765,0-50.273-22.691-50.273-50.683
                          C50.104,72.691,72.612,50,100.377,50C128.143,50,150.65,72.691,150.65,100.682z M143.294,187.333H58.277V462h85.017V187.333z M279.195,187.333h-81.541V462h81.541c0,0,0-101.877,0-144.181c0-38.624,17.779-61.615,51.807-61.615 c31.268,0,46.289,22.071,46.289,61.615c0,39.545,0,144.181,0,144.181h84.605c0,0,0-100.344,0-173.915 s-41.689-109.131-99.934-109.131s-82.768,45.369-82.768,45.369V187.333z"
                        />
                      </svg>
                      <span className="usa-sr-only">LinkedIn</span>{' '}
                    </a>{' '}
                    <a
                      data-testid="dolSocialMediaYouTube"
                      className="social-media-icon"
                      href="https://www.youtube.com/user/USDepartmentofLabor"
                      title="Department of Labor Youtube"
                    >
                      {/* ?xml version="1.0" encoding="utf-8"? */}
                      <svg
                        aria-hidden="true"
                        data-prefix="fab"
                        data-icon="youtube"
                        className="svg-inline--fa fa-youtube fa-w-18"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <desc>Youtube Icon</desc>
                        <path
                          fill="currentColor"
                          d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                        />
                      </svg>
                      <span className="usa-sr-only">Youtube</span>{' '}
                    </a>{' '}
                    <a
                      style={{ display: 'none' }}
                      data-testid="dolSocialMediaEmail"
                      className="social-media-icon"
                      href="javascript:window.open('https://public.govdelivery.com/accounts/USDOL/subscriber/new','Popup','width=800,height=500,toolbar=no,scrollbars=yes,resizable=yes'); void('');"
                      onClick={() => "window.status='Subscribe'; return true"}
                      onMouseOver={() => "window.status='Subscribe'; return true"}
                      onMouseOut={() => "window.status=''; return true"}
                      id="email"
                      title="Department of Labor Email"
                    >
                      {/* ?xml version="1.0" encoding="utf-8"? */}
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="envelope"
                        className="svg-inline--fa fa-envelope fa-w-16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <desc>Envelope Icon</desc>
                        <path
                          fill="currentColor"
                          d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                        />
                      </svg>
                      <span className="usa-sr-only">Email</span>{' '}
                    </a>{' '}
                  </div>
                </div>
                <div className="usa-width-one-half dol-utility-menu">
                  <div className="utility-links-block d-flex utility_block">
                    {' '}
                    <a href="https://www.dol.gov/general/findit">
                      Site Map
                    </a>{' '}
                    <a href="https://www.dol.gov/general/aboutdol/website-policies">
                      Important Website Notices
                    </a>{' '}
                    <a href="https://www.dol.gov/general/privacynotice">
                      Privacy &amp; Security Statement
                    </a>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default FooterComponent;
