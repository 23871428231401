/* eslint-disable react/jsx-no-bind */
import { React, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import PreContentComponent from '../data/PreContentComponent';
import HeroBanner from './HeroBanner';
import ApiExampleText from './ApiExampleText';
import '../../css/api_example.css';

function ApiExamples() {
  const [text, setText] = useState('Overview');
  let [navStat, setnavStat] = useState({ Overview: 'active first' });
  // const [snippetDefaultContent, snippetDefaultContent] = useState('Python');
  const [snippetContent1, setSnippetContent1] = useState('');
  const [snippetContent2, setSnippetContent2] = useState('');
  const [snippetContent3, setSnippetContent3] = useState('');
  const [snippetContent4, setSnippetContent4] = useState('');
  const [snippetContent5, setSnippetContent5] = useState('');
  const [snippetTypeJSON, setSnippetTypeJSON] = useState('Python');
  const [language1, setLangauge1] = useState('Python');
  const [language2, setLangauge2] = useState('Python');
  const [language3, setLangauge3] = useState('Python');
  const [language4, setLangauge4] = useState('Python');
  const [language5, setLangauge5] = useState('Python');

  function setNavLink(id) {
    document.getElementById(id).click();
  }
  function menuClick(
    text,
    defaultContent1,
    defaultContent2,
    defaultContent3,
    defaultContent4,
    defaultContent5,
  ) {
    navStat = {};
    if (!$(`#${text}`).hasClass('first')) {
      $('.first').css({
        border: '1px solid black',
        'background-color': '#FFF',
        color: 'black',
      });
    } else {
      $('.first').css({
        borderLeft: '8px solid #045EA3',
        'background-color': '#F1F1F1',
        color: 'black',
      });
    }
    setSnippetContent1(defaultContent1);
    setSnippetContent2(defaultContent2);
    setSnippetContent3(defaultContent3);
    setSnippetContent4(defaultContent4);
    setSnippetContent5(defaultContent5);
    setText(text);
  }

  // $('.nav-tabs li a').click(function (e) {
  //   e.preventDefault();
  //   e.stopImmediatePropagation();
  //   $(this).tab('show');
  // });

  function configCodeBlock1(language, type) {
    setSnippetContent1(type);
    setLangauge1(language);
  }

  function configCodeBlock2(language, type) {
    setSnippetContent2(type);
    setLangauge2(language);
  }

  function configCodeBlock3(language, type) {
    setSnippetContent3(type);
    setLangauge3(language);
  }

  function configCodeBlock4(language, type) {
    setSnippetContent4(type);
    setLangauge4(language);
  }

  function configCodeBlock5(language, type) {
    setSnippetContent5(type);
    setLangauge5(language);
  }

  function configCodeBlockJSON(language, type) {
    setSnippetTypeJSON(type);
    setLangauge1('JSON');
  }

  return (
    <div className="pre-content">
      <PreContentComponent spanText="API Examples" />
      <HeroBanner page="api" />
      <div className="container">
        <Row>
          <Col lg={3} id="meta_nav">
            <Nav
              defaultActiveKey="/home"
              id="meta_menu"
              className="flex-column"
            >
              <Nav.Link
                className={navStat.Overview}
                id="Overview"
                href="javascript:void(0)"
                onClick={() => menuClick('Overview')}
                eventKey="link-1"
              >
                Overview
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick('Dataset', 'getDatasetPython')}
                eventKey="link-2"
                href="javascript:void(0)"
                className={navStat.Dataset}
                id="Overview"
              >
                Get Datasets
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick('Metadata', 'getMetaDataPython')}
                eventKey="link-3"
                href="javascript:void(0)"
                className={navStat.Metadata}
                id="linkMetadata"
              >
                Get Metadata
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick(
                  'Data',
                  'limitParamPython',
                  'selectFieldPython',
                  'sortPython',
                  'filterBySinglePython',
                  'filterByMultiPython',
                )}
                eventKey="link-4"
                href="javascript:void(0)"
                className={navStat.Data}
                id="linkData"
              >
                Get Data
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick('Toolkit')}
                eventKey="link-5"
                href="javascript:void(0)"
                className={navStat.Toolkit}
              >
                Excel Toolkit
              </Nav.Link>
            </Nav>
          </Col>
          <Col lg={7} id="api_text_section">
            {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
            <h2 id="meta_heading" />
            <ApiExampleText
              type={text}
              snippetContent1={snippetContent1}
              snippetContent2={snippetContent2}
              snippetContent3={snippetContent3}
              snippetContent4={snippetContent4}
              snippetContent5={snippetContent5}
              snippetTypeJSON={snippetTypeJSON}
              jsonType=""
              language1={language1}
              language2={language2}
              language3={language3}
              language4={language4}
              language5={language5}
              configCodeBlock1={configCodeBlock1}
              configCodeBlock2={configCodeBlock2}
              configCodeBlock3={configCodeBlock3}
              configCodeBlock4={configCodeBlock4}
              configCodeBlock5={configCodeBlock5}
              configCodeBlockJSON={configCodeBlockJSON}
              setNavLink={setNavLink}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ApiExamples;
