/* eslint-disable */
import CodeBlocks from "./CodeBlocks";
import CodeBlocksJSON from "./CodeBlocksJSON";
import "../../css/api_example.css";

function ApiExampleText(props) {
  let type = props.type;
  const language1 = props.language1;
  const language2 = props.language2;
  const language3 = props.language3;
  const language4 = props.language4;
  const language5 = props.language5;
  const snippetContent1 = props.snippetContent1;
  const snippetContent2 = props.snippetContent2;
  const snippetContent3 = props.snippetContent3;
  const snippetContent4 = props.snippetContent4;
  const snippetContent5 = props.snippetContent5;
  // const snippetTypeJSON = props.snippetTypeJSON;
  const configCodeBlock1 = props.configCodeBlock1;
  const configCodeBlock2 = props.configCodeBlock2;
  const configCodeBlock3 = props.configCodeBlock3;
  const configCodeBlock4 = props.configCodeBlock4;
  const configCodeBlock5 = props.configCodeBlock5;
  // const configCodeBlockJSON = props.configCodeBlockJSON;

  const datseSetExample1 = `
  {
  "datasets": [
    {
    "id": 10000,
    "name": "vets_test_upload",
    "description": "vets_test_upload",
    "tablename": "VETS_vets_test_upload",
    "published_at": "2023-10-05Т01:57:30",
    "dataset_type": 1,
    "agency_id": 4,
    "agency": {
    "name": "Veterans' Employment and Training Service ",
    "abbr": "VETS"
    ｝，
    "frequency": "Ad-Hoc",
    *tabl ename arets test upload",
    "category_id": 10,
    "category": {
    "name": "Veterans"
    }
    },
    "category_name": "Veterans",
    "status": 4,
    "tag_list": [l,
    "created_at": "2023-10-05Т00:59:34",
    "updated_at": "2023-10-0501:57:30"
    ],
    "meta"：｛
    "current_page": 1,
    "next_page": null,
    "prev_page": null,
    "total_pages": 1,
    "total_count": 6
  }
}
    `;

  const datseSetExample2 = `
    # Last updated on 11/06/2023 using Python 3.9.

    # Import libraries
    import requests
    
    # Get datasets
    datasets_url = "https://apiprod.dol.gov/v4/datasets"
    datasets_request = requests.get(datasets_url, verify=False)
    datasets_json = datasets_request.json()
    `;
  const metadataLink = `https://apiprod.dol.gov/v4/get/<agency>/<api_url>/json/metadata?X-API-KEY=<api_key>`;

  const metadataExample1 = `{
      "datasets": [
      {
      "id": 10000,
      "name": "vets_test_upload",
      "description": "vets_test_upload"
      "tablename": "VETS_vets_test_upload",
      "published_at": "2023-10-05Т01:57:30",
      "agency": {
      "name": "Veterans' Employment and Training Service ",
      "abbr": "VETS"
      },
      "frequency": "Ad-Hoc",
      "api_url" : "
      "category d": 10,
      "name": "Veterans"
      }
      },
      "category_name": "Veterans",
      "status": 4,
      "tag_list": [],
      "created_at" : "2023-10-05T00:59:34"
      "updated_at" : "2023-10-05T01:57:30"
    }`;

  const dataExample1 = `
    {
      results": {
      "response_metadata":
      "data": [
      '{\"total_records\": 584}"
      
      "attendee" "Underwood, Jolyn",
      "agency":
      "date": "2022-10-25"
      
      "attendee" "Morris, Christopher P",
      "agency":
      "date": "2022-10-25"
      
      "attendee": "Gustafson, Aaron A",
      "agency":
      "VETS"
      "date": "2022-10-25"
      
      "attendee":
      "Reuss, Kevin L",
      "де" 2922-10-25°
      
      "attendee": "Kechter, Kelli R",
      "MSHA"
      3ee 222-10-25
      }`;

  const dataExample2 = `{"field":"back_wages", "operator":"gt", "value":"100000"}`;

  switch (type) {
    case "Overview":
      return (
        <>
          <h2>Overview</h2>
          <p>
            Our API examples were created to help you seamlessly integrate our
            data into your preferred software. We currently provide API examples
            for R, Python, and Excel and will be expanding the examples into
            other languages and software in the future.
          </p>
          <p>
            Use the left navigation bar to begin exploring our API examples:
          </p>
          <ul>
            <li>
              <b>Datasets</b> – Explore our data catalog, including dataset
              names, descriptions, categories, tags, and update frequency, as
              well as the publishing agency’s name.
            </li>
            <li>
              <b>Metadata</b> – Get detailed information about the variables in
              a specific dataset.
            </li>
            <li>
              <b>Data</b> – Get customized extracts of our data.
            </li>
          </ul>
        </>
      );

    case "Dataset":
      return (
        <>
          <h2>Get Datasets</h2>
          <p>
            The Datasets API serves our <a href="/datasets">Data Catalog</a> in
            JSON format. You can access the list of datasets in the API, which
            agencies publish them, how frequently datasets are updated, and how
            datasets are categorized and tagged. This provides essential
            information for browsing our datasets and offers the agency name
            abbreviation and api endpoint url, which are needed for constructing
            valid API calls and requesting metadata and data.
          </p>
          <p>
            The Datasets API can be accessed here:
            <br />
            <span>
              <a href="https://apiprod.dol.gov/v4/datasets">
                https://apiprod.dol.gov/v4/datasets
              </a>{" "}
              (note: no api key is required to access this endpoint)
            </span>
            {/* <pre className="prettyprint">{datseSetExample1}</pre> */}
            <br />
            <br />
            To integrate the datasets API directly into Python or R, use the
            code snippets below:
          </p>
          {/* <pre className="prettyprint">{datseSetExample2}</pre> */}

          <div id="code_block">
            <CodeBlocks
              language={language1}
              snippetContent={snippetContent1}
              configCodeBlock={configCodeBlock1}
              snippetContentPython="getDatasetPython"
              snippetContentR="getDatasetR"
            />
          </div>
        </>
      );

    case "Metadata":
      return (
        <>
          <h2 id="meta_heading">Get Metadata</h2>
          <p>
            The Metadata API serves comprehensive metadata that describe the
            variables in our datasets. Metadata can help you better understand
            the properties and characteristics of the variables within a
            dataset, inform whether the data will be potentially useful for
            specific purposes prior to access, and provide considerable benefit
            in automating the processing of the resulting data.
          </p>
          <p>
            The Metadata API can be accessed using this template:
            <br />
            https://apiprod.dol.gov/v4/get/<b>&lt;agency&gt;</b>/
            <b>&lt;endpoint&gt;</b>/json/metadata?X-API-KEY=
            <b>&lt;api_key&gt;</b>
          </p>
          <b>Example:</b>
          <p>
            Using{" "}
            <a href="https://dataportal.dol.gov/datasets/10280">
              Training Dataset (Industries)
            </a>{" "}
            as an example, you would populate &lt;agency&gt; with trng,
            &lt;endpoint&gt; with training_dataset_industries, and
            &lt;api_key&gt; with your <a href="/api-keys">API key</a>:
          </p>
          <p>
            https://apiprod.dol.gov/v4/get/<b>trng</b>/
            <b>training_dataset_industries</b>
            /csv/metadata?X-API-KEY=<b>&lt;api_key&gt;</b>
          </p>
          <p>
            For a complete list of agencies and endpoints, download our{" "}
            <a href="https://dol.gov/sites/dolgov/files/Data-Governance/Open%20Data%20Portal/agency-endpoint.csv">
              agency-endpoint table
            </a>
            .
          </p>
          <b>Code:</b>
          <p>
            To retrieve metadata directly into Python or R, use the code
            examples below:
          </p>
          <div id="code_block">
            <CodeBlocks
              language={language1}
              snippetContent={snippetContent1}
              configCodeBlock={configCodeBlock1}
              snippetContentPython="getMetaDataPython"
              snippetContentR="getMetaDataR"
            />
          </div>
          <h4>Exemplary Use Cases for Metadata</h4>
          <p>1. Comprehensibility: Rename the variables of a dataset.</p>
          <p>
            Use “short_name” and “full_name” to create a key / value pair to
            rename your dataset variables with plain-language equivalents.{" "}
          </p>
          <b>Example:</b>
          <ul id="indent">
            <li>
              “naic_cd”:“North American Industry Classification System Codes”
              would be used to replace the short name with the full name.
            </li>
          </ul>
          <p>
            2. Discovery: Identify categories of data that you can select and
            extract from the API.
          </p>
          <p>
            Look at the “allowed_value_list” for boolean and categorical
            variables. These variables have a defined set of valid values that
            you can query from.{" "}
          </p>
          <b>Example:</b>
          <ul id="indent">
            <li>
              The allowed value list for Fair Labor Standards Act Repeat/Willful
              violators is R;Repeat| W;Willful|RW;Repeat and Willful|N/A;Not
              applicable. You can subset this dataset for repeat violators by
              filtering records where flsa_repeat_violator = “R”.
            </li>
          </ul>
          <p>
            3. Discovery: Identify ranges of data for a variable that you can
            subset to optimize your API calls.
          </p>
          <p>
            Look at the “allowed_variable_range” for numeric, currency, date, or
            time variables. These variables have defined ranges for which values
            are valid.
          </p>
          <b>Example:</b>
          <ul id="indent">
            <li>
              The amount of back wages agreed to pay under the Fair Labor
              Standards Act has an allowed variable range of 0|50000000. You can
              subset data for any range of values within the allowed range, and
              also ensure that your storage format will accommodate the largest
              and smallest values.
            </li>
            <li>
              Citation issuance date has an allowed variable range of
              1972-01-01|present. You can subset data for any date range within
              the allowed range.
            </li>
          </ul>
          <p>
            4. Extension: Find data tables with related content that can/should
            be joined.
          </p>
          <p>
            Use “linked_tables” to identify variables that function as a primary
            or foreign key, and link aspects of these data with data in other
            tables.
          </p>
          <b>Example:</b>
          <ul id="indent">
            <li>
              The ‘activity_nr’ variable occurs in both the OSHA_inspection
              table (primary key), and the OSHA_violation table (foreign key),
              allows linkage across related data tables, and supports the
              identification of all the violations that were documented from a
              specific inspection or enforcement process.
            </li>
          </ul>
          <p>
            5. Efficiency: Understand the level of detail variables are intended
            to have.
          </p>
          <p>
            Use “precision” to identify the number of decimal places or degree
            of rounding for numeric or currency data, the level from which codes
            are taken in a hierarchical classification system like NAICS, or to
            the level of detail in date or time data. This supports integration
            and efficient use of data by clarifying the format of the variable
            and specifying the precision of calculations that these variables
            can support.
          </p>
          <b>Examaple</b>
          <ul id="indent">
            <li>
              Amount of back wages agreed to pay under the Fair Labor Standards
              Act is a currency amount with a precision of 2 (i.e. 2 decimal
              places).
            </li>
            <li>
              North American Industry Classification Codes have a precision of 6
              (i.e. 6-digit level).
            </li>
            <li>
              Citation date is stored in the API as yyyy-mm-dd hh:mm:ss but is
              only created with date content and all the times are set to
              00:00:00. The precision is set to day to reflect this.
            </li>
          </ul>
          <p>6. Comprehensibility: Identify patterns of missingness.</p>
          <p>
            Use the “missing_category_counts” to identify the list of values
            that are present in the data but indicate the value is missing,
            along with counts for indicators of missing values. This can be
            exceedingly helpful when building automated applications or analysis
            that use these data.
          </p>
          <b>Example</b>
          <ul id="indent">
            <li>
              N/A;3|NA;1|N/a;1|Not Available;1|Not available;1 are the missing
              category counts for employer street address. This describes the
              different ways in which missing data may be indicated, and helps
              in understanding how missing values are distributed across
              different categories.
            </li>
          </ul>
          <p>7. Reusability: Be aware of data quality issues.</p>
          <p>
            Use the “valueless_list” to get a link to an API call that captures
            a list of valueless data entries that resemble valid data but cannot
            be interpreted because they do not conform to a reference or
            standard value, or cannot be translated into a meaningful value.
          </p>
          <b>Example</b>
          <ul id="indent">
            <li>
              North American Industry Classification System Code entries 245321,
              365390, and 991387 appear to be valid industry codes, however,
              they do not conform to the{" "}
              <a href="https://www.census.gov/naics/">
                North American Industry Classification System standard
              </a>
              .
            </li>
          </ul>
          <p>8. Reusability: Explore our descriptive statistics. </p>
          <p>
            Use descriptive statistics, which exclude missing data, to
            understand the variable distribution of numeric and currency
            variables, category counts of allowed and missing values, and the
            range of possible values found in the data.
          </p>
          <b>Example:</b>
          <ul id="indent">
            <li>
              Filter records where back wages exceed the mean value of $100,000.
            </li>
            <li>
              Filter records for the full-service restaurants industry, which
              has the largest industry category count.
            </li>
            <li>
              Identify outliers, like an inspection date variable with a max
              value of 3590-01-10.
            </li>
            <li>
              Review the quartiles to ensure that the distribution of values
              present in the data will be useful for your analysis. If the 25%
              value, median value, and 75% value are all zeroes, the majority of
              the cases present will be registering zero as a value.
            </li>
          </ul>
        </>
      );

    case "Data":
      return (
        <>
          <h2 id="meta_heading">Get Data</h2>
          <p>
            The Data API serves customizable subsets of data. Select which
            fields and records you want and choose how records are formatted and
            sorted.
          </p>
          <p>
            The Data API can be accessed using this template:
            <br />
            https://apiprod.dol.gov/v4/get/<b>&lt;agency&gt;</b>/
            <b>&lt;endpoint&gt;</b>/json?X-API-KEY=<b>&lt;api_key&gt;</b>
          </p>
          <b>Example:</b>
          <p>
            Using{" "}
            <a href="https://dataportal.dol.gov/datasets/10280">
              Training Dataset (Industries)
            </a>{" "}
            as an example, you would populate &lt;agency&gt; with trng,
            &lt;endpoint&gt; with training_dataset_industries, and
            &lt;api_key&gt; with your <a href="/api-keys">API key</a> to get a
            10 record data sample:
          </p>
          <p>
            https://apiprod.dol.gov/v4/get/<b>trng</b>/
            <b>training_dataset_industries</b>
            /csv?X-API-KEY=<b>&lt;api_key&gt;</b>
          </p>
          <p>
            For a complete list of agencies and endpoints, download our{" "}
            <a href="https://dol.gov/sites/dolgov/files/Data-Governance/Open%20Data%20Portal/agency-endpoint.csv">
              agency-endpoint table
            </a>
            .
          </p>
          <b>Customize your data request</b>
          <p>
            You can customize your data request using optional API parameters.
            Default values are always applied if the user doesn’t specify
            parameters. The default parameters are described below.
          </p>
          <p>
            <b id="indent_right">(1)</b> Pick your preferred data format (json,
            xml, csv) – by default json is provided but you can substitute the
            word json with <b>xml</b> or <b>csv</b> in the request URL to change
            the format.
          </p>
          <p>
            https://apiprod.dol.gov/v4/get/&lt;agency&gt;/&lt;endpoint&gt;/
            <b>xml</b>?X-API-KEY=&lt;api_key&gt;{" "}
          </p>
          <p>
            https://apiprod.dol.gov/v4/get/&lt;agency&gt;/&lt;endpoint&gt;/
            <b>csv</b>?X-API-KEY=&lt;api_key&gt;
          </p>
          <p>
            <b id="indent_right">(2)</b> limit – add the "limit" parameter to
            your request to limit the number of records returned.{" "}
            <b>
              You can receive up to 5 MB of data or 10,000 records per request,
              whichever limit you hit first.
            </b>
          </p>
          <p>&limit=10000</p>
          <p>
            <b id="indent_right">(3)</b> offset – add the “offset” parameter to
            your request to offset the records returned. This parameter gets
            used in conjunction with the limit parameter when you need to make
            multiple API requests to get all of the records that you want.
          </p>
          <p>
            For example, if you want to get 20000 records with our max limit
            being 10000 records, you will need to make 2 API requests:
          </p>
          <p>&limit=10000 (gets you the first chunk of 10,000 records)</p>
          <p>
            &limit=10000&offset=10000 (gets you the second chunk of 10,000
            records)
          </p>
          <p>
            <b id="indent_right">(4)</b> Subset fields – add the “fields”
            parameter to your request to limit the returned data to only those
            field you want. To do this, provide a comma separated list of
            variable names after the fields parameter.
          </p>
          <p>&fields=field1,field2,field3</p>
          <p>
            <b id="indent_right">(5)</b> Sort order– add the “sort” parameter to
            your request to ensure the returned data is ordered optimally. To do
            this, provide a sort direction (“asc” for ascending or “desc” for
            descending) after the sort parameter.
          </p>
          <p>&sort=asc</p>
          <p>
            <b id="indent_right">(6)</b> Sort by – add the “sort_by” parameter
            to your request and provide the variable name you want to sort by.
          </p>
          <p>&sort_by=field1</p>
          <p>
            <b id="indent_right">(7)</b> Subset records – add the
            “filter_object” parameter to your request and provide a json
            formatted filter string to ensure that only records meeting specific
            criteria are in the returned data. After the filter object
            parameter, identify the variable you want to filter on (field), the
            filtering action (operator) and the comparison criteria (value). The
            operators we support include eq (equals), neq (not equals), gt
            (greater than), lt (less than), in, not_in, and like.
          </p>
          <p>&filter_object = {dataExample2}</p>
          <p>
            This filter will only return case data in which there were more than
            100k in back wages.
          </p>
          <p>
            To apply a “filter_object” directly in the web browser you need to{" "}
            <a href="https://www.urlencoder.org/">encode</a> the special
            characters.
          </p>
          <table>
            <thead></thead>
            <tbody>
              <tr>
                <td>Character: </td>
                <td>&#123;</td>
                <td>&#125;</td>
                <td>"</td>
                <td>SPACE</td>
                <td>,</td>
                <td>:</td>
              </tr>
              <tr>
                <td>Replace With: </td>
                <td>%7B</td>
                <td>%7D</td>
                <td>%22</td>
                <td>%20</td>
                <td>%2C</td>
                <td>%3A</td>
              </tr>
            </tbody>
          </table>
          <span style={{ display: "block", wordWrap: "break-word" }}>
            &filter_object=%7B%22field%22%3A%22back_wages%22%2C%20%22operator%22%3A%22gt%22%2C%20%22value%22%3A%22100000%22%7D
          </span>
          <p>
            <br />
            For help building a customized request URL, including automated
            “filter_object” URL encoding, we recommend using the API Query
            Builder located on every dataset page you navigate to from the{" "}
            <a href="/datasets">Data Catalog</a>.
          </p>
          <img
            src={require("../../images/buildAPI.jpg")}
            alt="picture of dataset"
            className="standalone_image"
          />
          <br />
          <br />
          <b>Code:</b>
          <p>
            If you prefer to programmatically build your request, use the code
            examples below to learn how to customize your data requests. Each
            example will use our API training dataset called{" "}
            <a href="https://dataportal.dol.gov/datasets/10280">
              Training Dataset (Industries)
            </a>
            .
          </p>
          <p>
            <i>
              Limit parameter - Return the maximum number of records (up to
              10,000):
            </i>
          </p>
          <div id="code_block1">
            <CodeBlocks
              language={language1}
              snippetContent={snippetContent1}
              configCodeBlock={configCodeBlock1}
              snippetContentPython="limitParamPython"
              snippetContentR="limitParamR"
            />
          </div>
          <br />
          <p>
            <i>Fields parameter - return only the industry and value fields:</i>
          </p>
          <div id="code_block2">
            <CodeBlocks
              language={language2}
              snippetContentPython="selectFieldPython"
              snippetContentR="selectFieldR"
              snippetContent={snippetContent2}
              configCodeBlock={configCodeBlock2}
            />
          </div>
          <br />
          <p>
            <i>
              Sort and sort_by parameters – sort records in descending order by
              year:
            </i>
          </p>
          <div id="code_block3">
            <CodeBlocks
              language={language3}
              snippetContentPython="sortPython"
              snippetContentR="sortR"
              snippetContent={snippetContent3}
              configCodeBlock={configCodeBlock3}
            />
          </div>
          <br />
          <p>
            <i>Filter_object parameter - Subset dataset records:</i>
          </p>
          <p>
            <i id="indent_right">(a) Single condition filter</i>
          </p>
          <div id="code_block4">
            <CodeBlocks
              language={language4}
              snippetContentPython="filterBySinglePython"
              snippetContentR="filterBySingleR"
              snippetContent={snippetContent4}
              configCodeBlock={configCodeBlock4}
            />
          </div>
          <br />
          <p>
            <i id="indent_right">(b) Multiple condition filter</i>
          </p>
          <div id="code_block5">
            <CodeBlocks
              language={language5}
              snippetContentPython="filterByMultiPython"
              snippetContentR="filterByMultiR"
              snippetContent={snippetContent5}
              configCodeBlock={configCodeBlock5}
            />
          </div>
        </>
      );

    case "Toolkit":
      return (
        <>
          <h2 id="meta_heading">Excel Toolkit</h2>
          <p>
            The Excel Toolkit enables Excel users to connect to our APIs using a
            familiar interface without needing to learn new software or
            programming languages. Once you connect to our APIs, you can turn on
            automatic data refresh to keep your data up-to-date without manual
            intervention. This is particularly useful for real-time or regularly
            updated data sources. You can then use Excel's robust set of
            analytical tools for your data analysis.
          </p>
          <p>
            To get started, <a href="/registration">register or sign-in</a> to
            get your <a href="/api-keys">API key</a>, then download the{" "}
            <a href="excel/dol-api-excel-toolkit.xlsx">Excel Toolkit</a>. Follow
            the provided instructions to start creating API queries.
          </p>
        </>
      );

    default:
  }
}

export default ApiExampleText;
