import React from 'react';
import { Button, Alert, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Spinner from 'react-bootstrap/Spinner';
import ContentComponent from './data/ContentComponent';

class ApiKeys extends React.Component {
  constructor() {
    super();
    this.state = {
      alert: null,
    };
  }

  componentDidMount() {
    this.props.setPrevPath('');
  }

  createNewKey = () => {
    const accessToken = localStorage.getItem('token');
    if (this.props.tokens.length < 5) {
      fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/apikey`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            this.props.fetchAPIKeys();
            this.setState({
              alert: {
                action: 'info',
                msg: 'Created a new API key.',
              },
            });
          }
          this.removeAlert();
        });
    } else {
      this.setState({
        alert: {
          action: 'warning',
          msg: 'The maximum amount of API keys per user is five, if you want to more API keys please reach out to webmaster@dol.gov',
        },
      });
      this.removeAlert();
    }
  };

  handleDelete = (token) => {
    const accessToken = localStorage.getItem('token');
    if (token.is_default) {
      alert('Can not delete default key');
    } else {
      fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/apikey/${token.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.props.fetchAPIKeys();
          this.setState({
            alert: {
              action: 'warning',
              msg: 'API key was deleted.',
            },
          });
          this.removeAlert();
        });
    }
  };

  handleDefault = (token) => {
    const accessToken = localStorage.getItem('token');

    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/apikey/${token.id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.props.fetchAPIKeys();
        this.setState({
          alert: {
            action: 'info',
            msg: 'Your default API key has been changed.',
          },
        });
        this.removeAlert();
      });
  };

  removeAlert = () => {
    setTimeout(() => {
      this.setState({
        alert: null,
      });
    }, 5000);
  };

  render() {
    return (
      <>
        <Helmet>
          <title>API Keys</title>
          <meta
            name="description"
            content="This is a different description for this route."
          />
        </Helmet>
        <ContentComponent
          mainHeader="API Keys"
          identifier=""
          className="basic-title-banner-title-container"
          spanText="API Keys"
          centered={false}
        />
        {this.props.tokensLoading ? (
          <Col className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Col>
        ) : (
          <div className="container mt-4">
            <div className="row justify-content-around">
              {this.props.tokens.length > 0 ? (
                <div className="col-lg-6">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      {this.state.alert ? (
                        this.state.alert.action === 'info' ? (
                          <Alert variant="info">{this.state.alert.msg}</Alert>
                        ) : (
                          <Alert variant="danger">{this.state.alert.msg}</Alert>
                        )
                      ) : null}
                    </div>
                  </div>
                  <div className="table-responsive-sm">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th width="50">S/N</th>
                          <th className="text-center">API Keys</th>
                          <th colSpan="2" className="text-center">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.tokens
                          ? this.props.tokens.map((token, idx) => (token.is_default ? (
                            <tr key={idx}>
                              <td>{idx + 1}.</td>
                              <td>{token.key}</td>
                              <td
                                colSpan="2"
                                width="200"
                                className="text-center"
                              >
                                Default key
                              </td>
                            </tr>
                          ) : (
                            <tr key={idx}>
                              <td>{idx + 1}.</td>
                              <td>{token.key}</td>
                              <td className="text-left">
                                <u>
                                  <a
                                    href="#"
                                    title="make key default"
                                    onClick={() => this.handleDefault(token)}
                                  >
                                    Make key {idx + 1} default
                                  </a>
                                </u>
                              </td>
                              <td className="text-left">
                                <u>
                                  <a
                                    href="#"
                                    title="delete key"
                                    onClick={() => this.handleDelete(token)}
                                  >
                                    Delete key {idx + 1}
                                  </a>
                                </u>
                              </td>
                            </tr>
                          )))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : this.props.tokens.length === 0 ? (
                <h2>No API Keys</h2>
              ) : (
                <h2>No API keys at the moment due to internal server error.</h2>
              )}
              <div className="col-lg-4">
                {this.props.currentUser ? (
                  <div>
                    <p>
                      Email:
                      {this.props.currentUser.email}
                    </p>
                  </div>
                ) : null}
                {this.props.tokens.length >= 0 ? (
                  <Button variant="primary" onClick={this.createNewKey}>
                    Create New Key
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ApiKeys;
