/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function ContentComponent(props) {
  const {
    spanText,
    mainHeader,
    className,
    centered,
    identifier,
    displayName,
    title,
  } = props;
  const RegistrationOMB = () => {
    return (
      <div
        style={{
          textAlign: "right",
          margin: "10px 20px 0 0",
          fontSize: "11px",
          lineHeight: "5px",
        }}
      >
        <p>OMB No. 1290-0044</p>
        <p>Expiration 11/30/2026</p>
      </div>
    );
  };
  return (
    <div className="pre-content">
      <div id="block-opa-theme-breadcrumbs">
        <div id="breadcrumbs-container" className="container">
          <nav role="navigation" aria-labelledby="system-breadcrumb">
            <h2 id="system-breadcrumb" className="visually-hidden">
              Breadcrumb
            </h2>
            <ul className="breadcrumbs">
              <li>
                <Link data-testid="homeLink" id="skipLink" to="/">
                  Home
                </Link>
                <span className="breadcrumbs-separator">
                  <svg
                    className="svg-inline--fa fa-chevron-right fa-w-10"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="chevron-right"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    data-fa-i2svg
                  >
                    <path
                      fill="currentColor"
                      d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                    />
                  </svg>
                </span>
              </li>
              <li>
                <span>{spanText}</span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {!title ? (
        <div
          className="views-element-container"
          id="block-views-block-homepage-hero-block-4"
        >
          {displayName == "registration" ? <RegistrationOMB /> : ""}
          <div className="block-views-block-homepage-hero-block-4">
            <div id="main-content" tabIndex={-1} className={className}>
              <h1 id={identifier} className={centered ? "text-center" : ""}>
                {mainHeader}
              </h1>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

ContentComponent.propTypes = {
  centered: PropTypes.bool,
  className: PropTypes.string,
  mainHeader: PropTypes.string,
  spanText: PropTypes.string,
  identifier: PropTypes.string,
};

ContentComponent.defaultProps = {
  centered: true,
  className: "basic-title-banner-title-container",
  mainHeader: "",
  spanText: "",
  identifier: "",
};

export default ContentComponent;
