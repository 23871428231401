import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import jsonData from '../data/data.json';

function ModalComponent(props) {
  // TODO: Have to refactor this component to make it reusable.
  const termsAndConditions = jsonData; // Expecting this will be a call in future.
  const externalLinkPopUp = (event) => {
    const openExternalLink = window.confirm(
      termsAndConditions.exiting_tab_text,
    );
    if (openExternalLink) {
      window.open(event.target.href, '_blank');
    }
    event.preventDefault();
  };
  const returnedData = termsAndConditions.rest_of_content.map((data) => (
    <>
      <h2 className="block-opa-theme-page-title">{data.heading}</h2>
      <p className="field field--name-body field--type-text-with-summary field--label-hidden clearfix legacy-page">
        {data.content}
      </p>
    </>
  ));
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal_width"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          data-testid="modal-header"
          id="contained-modal-title-vcenter"
        >
          <h4>{props.heading}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ maxHeight: 'calc(100vh - 250px)', overflow: 'auto' }}
      >
        <h2 data-testid="terms-heading" className="block-opa-theme-page-title">
          {termsAndConditions.terms.heading}
        </h2>
        <p className="field field--name-body field--type-text-with-summary field--label-hidden clearfix legacy-page">
          {termsAndConditions.terms.content}
          <a
            className="text-primary"
            onClick={(e) => externalLinkPopUp(e)}
            href={`${termsAndConditions.terms.privacy_notice_link}`}
          >
            {' '}
            Website Policies and Notices
          </a>
          {' and'}
          <a
            className="text-primary"
            onClick={(e) => externalLinkPopUp(e)}
            href={`${termsAndConditions.terms.website_policies_link}`}
          >
            {' '}
            Data Protection and Privacy Policy
          </a>
          {' (collectively, the "Agreements")'}
          .
        </p>
        {returnedData}
      </Modal.Body>
      <Modal.Footer data-testid="footer-revised-date">
        <p>{termsAndConditions.revised_date}</p>
      </Modal.Footer>
    </Modal>
  );
}

ModalComponent.propTypes = {
  show: PropTypes.bool,
  dialogClassName: PropTypes.bool,
  onHide: PropTypes.func,
  heading: PropTypes.string,
};

ModalComponent.defaultProps = {
  show: false,
  dialogClassName: false,
  onHide: () => {},
  heading: 'Terms of Service',
};

export default ModalComponent;
