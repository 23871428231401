/* eslint-disable no-param-reassign */
const mutationObserver = () => new MutationObserver((mutations) => {
  mutations.forEach(() => {
    document.querySelectorAll('.btn.try-out__btn.cancel').forEach((div) => {
      div.style.visibility = 'hidden';
    });
    document
      .querySelectorAll('.btn.try-out__btn:not(.cancel)')
      .forEach((div) => {
        div.click();
      });
  });
});

export default mutationObserver;
