import React from 'react';

function UsaHeaderBanner() {
  return (
    <>
      <div className="usa-banner">
        <div className="usa-accordion">
          <header
            data-testid="usaBannerHeader"
            className="usa-banner__header"
            aria-label="Official United States Government Website Disclaimer"
          >
            <div data-testid="usaInnerBanner" className="usa-banner__inner">
              <div className="grid-col-auto">
                <img
                  data-testid="usaBannerHeaderFlag"
                  className="usa-banner__header-flag"
                  src="https://www.dol.gov/drupal-assets/themes/opa_theme/img/flag-favicon-57.png"
                  alt="United States flag"
                />
              </div>
              <div
                data-testid="usaBannerOfficialBanner"
                className="grid-col-fill tablet:grid-col-auto"
              >
                <p>An official website of the United States government.</p>
                <p className="usa-banner__header-action" aria-hidden="true">
                  Here’s how you know
                </p>
              </div>
              <button
                data-testid="usaClickToExpandAccordion"
                className="usa-accordion__button usa-banner__button"
                aria-expanded="false"
                aria-controls="gov-banner"
                title="Click to expand"
                type="button"
              >
                <span className="usa-banner__button-text">
                  Here’s how you know
                </span>
              </button>
            </div>
          </header>
          <div
            data-testid="usaBannerContent"
            className="usa-banner__content usa-accordion__content"
            id="gov-banner"
            aria-hidden="true"
          >
            <div className="grid-row grid-gap-lg">
              <div className="usa-banner__guidance tablet:grid-col-6">
                <img
                  data-testid="usaBannerDotGovIcon"
                  className="usa-banner__icon usa-media-block__img"
                  src="https://www.dol.gov/drupal-assets/themes/opa_theme/img/icon-dot-gov.svg"
                  alt="Dot gov"
                />
                <div
                  data-testid="usaBannerFederalGovtMessage"
                  className="usa-media-block__body"
                >
                  <p>
                    <strong>The .gov means it’s official.</strong>
                    {' '}
                    <br />
                    Federal government websites often end in .gov or .mil. Before
                    sharing sensitive information, make sure you’re on a federal
                    government site.
                  </p>
                </div>
              </div>
              <div
                data-testid="usaBannerSecureSite"
                className="usa-banner__guidance tablet:grid-col-6"
              >
                <img
                  data-testid="usaBannerLockIcon"
                  className="usa-banner__icon usa-media-block__img"
                  src="https://www.dol.gov/drupal-assets/themes/opa_theme/img/icon-https.svg"
                  alt="Https"
                />
                <div
                  data-testid="usaBannerLockIconMessage"
                  className="usa-media-block__body"
                >
                  <p>
                    <strong>The site is secure.</strong>
                    {' '}
                    <br />
                    The
                    {' '}
                    <strong>https://</strong>
                    {' '}
                    ensures that you are connecting
                    to the official website and that any information you provide
                    is encrypted and transmitted securely.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="top-agency-name"
        className="banner-second container d-flex align-items-center"
      >
        <div
          data-testid="usaAgencyBannerHeader"
          className="agency-container agency-container--small d-flex align-items-center container"
        >
          <div className="agency" tabIndex={-1}>
            <a data-testid="usaBannerLink" href="https://www.dol.gov">
              <div className="d-flex align-items-center dol-logo-area--small">
                <div className="DOL-header-logo">
                  <img
                    data-testid="usaBannerHeaderLogo"
                    src="https://www.dol.gov/drupal-assets/themes/opa_theme/img/Agency_DOL_Logo_dark.svg"
                    alt="United States Department of Labor"
                  />
                </div>
                <h2 data-testid="dolUSAHeader">U.S. Department of Labor</h2>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsaHeaderBanner;
