/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-param-reassign */
import React from 'react';
import {
  Button, Form, Col, Row, Container, Modal,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { useAuth } from 'oidc-react';
import ContentComponent from '../data/ContentComponent';
import mainLogo from '../../images/login_gov.jpg';
import '../../css/registration.css';

function RegistrationComponent() {
  const auth = useAuth();
  return (
    <>
      <Helmet>
        <title>Sign In or Register</title>
        <meta
          name="description"
          content="This is a different description for this route."
        />
      </Helmet>
      <ContentComponent
        mainHeader="Sign In or Register"
        className="basic-title-banner-title-container"
        spanText="Register"
        centered
      />
      <Formik />
      <Form noValidate>
        <Form.Row className="justify-content-center">
          <Form.Group as={Col} md={8} sm={10}>
            <Form.Text className="form-row formText">
              You must be signed in to your account to retrieve data. We use
              LOGIN.GOV to authenticate. Sign into or create a new LOGIN.GOV
              account to use the DOL API.
            </Form.Text>
            <h3>Sign In or create a new Login.gov account</h3>
            <a
              onClick={() => {
                localStorage.clear();
                auth.signIn();
              }}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  localStorage.clear();
                  auth.signIn();
                }
              }}
            >
              <img
                src={mainLogo}
                className="loginGovImg bumpers clickable"
                alt="Login to Login.gov"
                tabIndex={0}
              />
            </a>
            <h3>Terms of Use</h3>
            <Form.Text className="form-row formText">
              You are about to access a U.S. Government computer/information
              system. Access to this system is restricted to authorized users
              only. Unauthorized access, use, or modification of this computer
              system or of the data contained herein, or in transit to/from this
              system, may constitute a violation of Title 18, United States
              Code, Section 1030 and other federal or state criminal and civil
              laws. These systems and equipment are subject to monitoring to
              ensure proper performance of applicable security features or
              procedures. Such monitoring may result in the acquisition,
              recording and analysis of all data being communicated,
              transmitted, processed or stored in this system by a user. If
              monitoring reveals possible misuse or criminal activity, notice of
              such may be provided to supervisory personnel and law enforcement
              officials as evidence. Anyone who accesses a Federal computer
              system without authorization or exceeds their access authority,
              and by any means of such conduct obtains, alters, damages,
              destroys, or discloses information, or prevents authorized use of
              information on the computer, may be subject to fine or
              imprisonment, or both. I understand that I am personally
              responsible for my use and any misuse of my access including my
              system account and password. I understand that by accessing a U.S.
              Government information system that I must comply with the
              prescribed policies and procedures. I acknowledge receipt of,
              understand my responsibilities, and will comply with the rules of
              behavior for this system.
            </Form.Text>
          </Form.Group>
        </Form.Row>
      </Form>
    </>
  );
}

export default withRouter(RegistrationComponent);
