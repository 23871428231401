/* eslint-disable global-require */
import { React, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import PreContentComponent from '../data/PreContentComponent';
import HeroBanner from './HeroBanner';
import '../../images/gs-dataset.png';
import '../../css/getting_started.css';

function GettingStarted() {
  return (
    <div className="pre-content">
      <PreContentComponent spanText="Getting Started" />
      <HeroBanner page="getting-started" />
      <div className="container getting_started">
        <div className="row getting_started_section">
          <div className="col-sm-12 col-md-6 col-lg-8">
            <div className="row">
              <h3 id="header_text">How do I get started?</h3>

              <h4>(1) Register for an account.</h4>
              <p>
                To begin using our APIs, you need to authenticate with LOGIN.GOV
                and complete our registration process to receive an API key. You
                need an API key for every request for metadata and data.{' '}
                <a href="/registration">Sign In or Register</a>.
              </p>
              <h4>(2) Select a Dataset.</h4>

              <h5>Finding Data in the Data Catalog</h5>
              <p>
                The <a href="datasets">Data Catalog</a> is our inventory of
                datasets available in the API. You can sort and filter datasets
                to find the one you want. Filters include categories, tags, and
                agencies. Filter using the left sidebar.
              </p>

              <img
                src={require('../../images/gs-dataset.png')}
                alt="picture of dataset"
                className="standalone_image"
              />

              <h4>(3) Query Data</h4>
              <p>
                Once you select a dataset, we provide comprehensive
                documentation to help you understand, access, and use our data.
              </p>
              <p>Documentation includes:</p>
              <ul>
                <li>Dataset information</li>
                <li>Field information (names, descriptions, and data types)</li>
                <li>A 10-record data preview</li>
                <li>
                  A simple tool for building API Queries that we call the “API
                  Query Builder”
                </li>
                <li>
                  Code snippets to integrate the API with a variety of
                  programming languages.
                </li>
              </ul>
              <h5>If you're not a Programmer</h5>
              <p>
                We recommend using the API Query Builder. This tool will help
                you to select which fields and records you want. It will also
                let you choose how records are formatted (json, xml, csv) and
                sorted.
              </p>
              <img
                src={require('../../images/gs-document.png')}
                className="standalone_image"
                alt="picture of document"
              />
              <h5>If you're a Programmer</h5>
              <p>
                We recommend using the Code Snippets. These snippets will help
                you connect to the API with popular programming languages. They
                will also allow you to get dataset metadata and a 10-record
                preview of the data.
              </p>
              <img
                src={require('../../images/gs-code-snippets.png')}
                className="standalone_image"
                alt="picture of code snippet"
              />
              <div>
                <p>
                  For help building more advanced queries, review the{' '}
                  <a href="/user-guide">API User Guide</a> and check out our{' '}
                  <a href="/api-examples">API Examples</a>.
                </p>
              </div>
              <h3>Videos</h3>
              <p>
                Prefer learning by video? View our{' '}
                <a href="/video-tutorials">Video Tutorials</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GettingStarted;
