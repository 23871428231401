/* eslint-disable react/button-has-type */
import React, { useReducer } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import UsaHeaderBanner from '../data/UsaHeaderBanner';

const initialState = {
  resourcesLink: 'cd-secondary-nav is-hidden',
  gotQuestionsLink: 'cd-secondary-nav is-hidden',
  accountLink: 'cd-secondary-nav is-hidden',
  apiLink: 'cd-secondary-nav is-hidden',
};

function reducer(state, action) {
  switch (action.type) {
    case 'showResourcesLink':
      return { ...state, resourcesLink: 'cd-secondary-nav' };
    case 'showAccountLink':
      return { ...state, accountLink: 'cd-secondary-nav' };
    case 'showApiLink':
      return { ...state, apiLink: 'cd-secondary-nav' };

    case 'hideResourcesLink':
      return { ...state, resourcesLink: 'cd-secondary-nav is-hidden' };
    case 'hideAccountLink':
      return { ...state, accountLink: 'cd-secondary-nav is-hidden' };
    case 'hideApiLink':
      return { ...state, apiLink: 'cd-secondary-nav is-hidden' };
    default:
      return null;
  }
}
function NavBarComponent({
  currentUser, logout, sendKeyPress, clearKeyPress,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  function menuAction(action, last) {
    if (
      sendKeyPress().type === 'click'
      || (sendKeyPress().key === 'Tab' && sendKeyPress().shiftKey === true)
      || last === true
    ) {
      setTimeout(() => {
        dispatch({
          type: action,
        });
      }, 500);
      clearKeyPress();
    }
  }
  const auth = useAuth();

  const toggleMenuButton = () => {
    const menuButton = document.getElementById('menu_button');
    if (menuButton && window?.innerWidth <= 992) {
      menuButton.click();
    }
  };

  return (
    <div data-testid="agencyHeader" className="agency-header">
      <header
        className="header cf usa-header usa-header-extended cd-main-header"
        role="banner"
      >
        <UsaHeaderBanner />
        {/* END the US Government bar and DOL bar . DO NOT CHANGE. */}
        {/* START Agency name, contact and search box section */}
        <div
          data-testid="usaOpenDataPortalDiv"
          className="container d-flex align-items-center agency-name"
        >
          {/* Update HTML link tag, title tag and text */}
          <h2>
            <Link data-testid="usaBannerOpenDataPortal" to="/">
              Open Data Portal
              <span id="beta_text"> beta</span>
            </Link>
          </h2>
          <div className="header-right-container">
            <div className="secondarylinks-container">
              <div className="align-items-center d-flex">
                <ul className="cd-header-buttons">
                  <li>
                    <div className="dol-menu-button">
                      <button
                        data-testid="usaHeaderMenuButton"
                        className="usa-menu-btn dol-menu-button usa-button-primary"
                        id="menu_button"
                      >
                        Menu
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              data-testid="usaHeaderSearchBoxDiv"
              className="searchbox-container nav-hide-on-mobile"
            >
              <div id="block-searchbox-2">
                <div className="field field--name-body field--type-text-with-summary field--label-hidden clearfix">
                  <div id="search-placeholder" className="form">
                    <div className="input-group">
                      <form
                        acceptCharset="UTF-8"
                        action="https://search.usa.gov/search"
                        id="search_form"
                        method="get"
                        className="searchbox d-flex usa-search usa-search--small"
                      >
                        <div id="header_subsection">
                          {currentUser ? (
                            <>
                              <Link
                                to="/"
                                onClick={() => {
                                  auth.signOut();
                                  logout();
                                  toggleMenuButton();
                                }}
                              >
                                <b>Sign Out</b>
                              </Link>
                              <span id="separator"> | </span>
                              <Link to="/api-keys">API Key</Link>
                            </>
                          ) : (
                            <Link to="/registration">Sign In / Register</Link>
                          )}
                          <span id="separator"> | </span>
                          <Link to="/contact-us">Contact Us</Link>
                        </div>
                        <div role="search">
                          <div
                            style={{
                              margin: 0,
                              padding: 0,
                              display: 'inline',
                            }}
                          >
                            <input name="utf8" type="hidden" defaultValue="✓" />
                          </div>
                          <label
                            data-testid="usaHeaderSearchLable"
                            htmlFor="query"
                            className="sr-only"
                          >
                            Search
                          </label>
                          <input
                            id="affiliate"
                            name="affiliate"
                            type="hidden"
                            defaultValue="www.dol.gov"
                          />
                          <input
                            data-testid="usaHeaderSearchBox"
                            className="searchbox-input usa-input"
                            id="query"
                            aria-label="search"
                            type="text"
                            name="query"
                            autoComplete="off"
                            placeholder="Search"
                            tabIndex={0}
                          />
                          <span className="input-group-btn">
                            <button
                              data-testid="usaHeaderSearchBoxSubmit"
                              type="submit"
                              title="Click to Search"
                              className="usa-button search-button"
                            >
                              <span className="usa-sr-only">Search</span>
                            </button>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END Agency name, contact and search box section */}
        {/* START Agency Navigation menu  */}
        <div data-testid="headerContent" id="header-content">
          <div>
            <h2 className="visually-hidden">submenu</h2>
            <div className="agency-nav-container" id="dol-primary-menu">
              <div data-testid="headerContainer" className="container">
                <ul
                  className="clearfix agency-submenu sub-nav-group cd-primary-nav is-fixed main-primary-menu inner-menu"
                  id="cd-primary-nav-secondary-desktop"
                >
                  {/* Updates HTML Links and Text links  */}
                  <li>
                    <Link
                      data-testid="navBarHomeLink"
                      to="/"
                      onClick={() => toggleMenuButton()}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-testid="navBarShowAboutLink"
                      to="/metadata"
                      onClick={() => toggleMenuButton()}
                    >
                      Metadata
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-testid="navBarDataSets"
                      to="/datasets"
                      onClick={() => toggleMenuButton()}
                    >
                      Data Catalog
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-testid="navBarShowAboutLink"
                      to="/visualization-gallery"
                      onClick={() => toggleMenuButton()}
                    >
                      Visualization Gallery
                    </Link>
                  </li>
                  <li className="has-children" aria-expanded="false">
                    <Link
                      to="#"
                      onClick={() => {
                        dispatch({
                          type: 'showResourcesLink',
                        });
                      }}
                      onBlur={(event) => {
                        menuAction('hideResourcesLink', false);
                      }}
                    >
                      <span className="menutitle">Resources</span>
                      <span className="menuarrow">
                        <p className="backtext">Back</p>
                        <svg
                          className="svg-inline--fa fa-chevron-down fa-w-14"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="chevron-down"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          data-fa-i2svg
                        >
                          <desc>Chevron down</desc>
                          <path
                            fill="currentColor"
                            d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                          />
                        </svg>
                      </span>
                    </Link>
                    <ul className={state.resourcesLink}>
                      <li className="has-children">
                        <ul className="main-primary-menu inner-agency-ul">
                          <li className="has-children" aria-expanded="false">
                            <h4>
                              <span>Resources</span>
                            </h4>
                            <ul>
                              <li>
                                <Link
                                  to="/getting-started"
                                  onClick={() => toggleMenuButton()}
                                >
                                  <b>Getting Started</b>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/user-guide"
                                  onClick={() => toggleMenuButton()}
                                >
                                  <b>API User Guide</b>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/api-examples"
                                  onClick={() => toggleMenuButton()}
                                >
                                  <b>API Examples</b>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/video-tutorials"
                                  onClick={() => toggleMenuButton()}
                                >
                                  <b>Video Tutorials</b>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/faq"
                                  onClick={() => toggleMenuButton()}
                                >
                                  <b>Frequently Asked Questions</b>
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      data-testid="navBarShowAboutLink"
                      to="/about"
                      onClick={() => toggleMenuButton()}
                    >
                      About
                    </Link>
                  </li>
                  <li
                    className={`has-children ${
                      currentUser ? ' ' : 'visually-hidden'
                    } signin_mobile`}
                    aria-expanded="false"
                  >
                    <a
                      href="#"
                      onClick={() => dispatch({
                        type: 'showAccountLink',
                      })}
                      onBlur={() => {
                        menuAction('hideAccountLink', false);
                      }}
                    >
                      <span className="menutitle">Account</span>
                      <span className="menuarrow">
                        <p className="backtext">Back</p>
                        <svg
                          className="svg-inline--fa fa-chevron-down fa-w-14"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="chevron-down"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          data-fa-i2svg
                        >
                          <desc>Chevron down</desc>
                          <path
                            fill="currentColor"
                            d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                          />
                        </svg>
                      </span>
                    </a>
                    <ul className={state.accountLink}>
                      <li className="has-children">
                        <ul className="main-primary-menu inner-agency-ul">
                          <li className="has-children" aria-expanded="false">
                            <h4>
                              <span>Account</span>
                            </h4>
                            <ul>
                              <li>
                                <Link
                                  to="/api-keys"
                                  onClick={() => toggleMenuButton()}
                                >
                                  <b>API KEYS</b>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/"
                                  onClick={() => {
                                    auth.signOut();
                                    logout();
                                    toggleMenuButton();
                                  }}
                                  onBlur={() => {
                                    menuAction('hideAccountLink', true);
                                  }}
                                >
                                  <b>SIGN OUT</b>
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="signin_mobile">
                    <Link
                      to="/registration"
                      onClick={() => {
                        toggleMenuButton();
                      }}
                      className={`no_megamenu_drop ${
                        currentUser ? 'visually-hidden' : ''
                      }`}
                      tabIndex={currentUser ? -1 : ''}
                    >
                      Sign In
                    </Link>
                  </li>
                </ul>
                <div className="nav-show-on-mobile">
                  <div id="block-searchbox-2">
                    <div className="field field--name-body field--type-text-with-summary field--label-hidden clearfix">
                      <div id="search-placeholder" className="form">
                        <div className="input-group">
                          <form
                            acceptCharset="UTF-8"
                            action="https://search.usa.gov/search"
                            id="search_form"
                            method="get"
                            className="searchbox d-flex usa-search usa-search--small"
                          >
                            <div role="search">
                              <div
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  display: 'inline',
                                }}
                              >
                                <input
                                  name="utf8"
                                  type="hidden"
                                  defaultValue="✓"
                                />
                              </div>
                              <label htmlFor="query" className="sr-only">
                                Search
                              </label>
                              <input
                                id="affiliate"
                                name="affiliate"
                                type="hidden"
                                defaultValue="www.dol.gov"
                              />
                              <input
                                className="searchbox-input usa-input"
                                id="query"
                                aria-label="search"
                                type="text"
                                name="query"
                                autoComplete="off"
                                placeholder="Search"
                                tabIndex={0}
                              />
                              <span className="input-group-btn">
                                <button
                                  type="submit"
                                  title="Click to Search"
                                  className="usa-button search-button"
                                >
                                  <span className="usa-sr-only">Search</span>
                                </button>
                              </span>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default NavBarComponent;
