/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import {
  Col, Row, Container, Button, Tabs, Tab,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import SwaggerUI from 'swagger-ui-react';
import '../css/test.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Spinner from 'react-bootstrap/Spinner';
import HTMLReactParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from 'html-react-parser';
import camera from '../images/video_icon.png';
import '../css/global.css';
import '../css/data_details.css';

class Detail extends React.Component {
  constructor() {
    super();
    this.state = {
      name: '',
      dataset: null,
      dataPreview: null,
      dataset_metadatum: [],
      pLang: '',
      loading: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.setPrevPath('');

    fetch(
      `${process.env.REACT_APP_DATA_PROXY_URL}${this.props.location.pathname}`,
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          name: data.dataset.name,
          dataset: data.dataset,
          dataset_metadatum: data.dataset.dataset_metadatum,
          dataPreview: JSON.parse(data.dataset.dataset_preview),
          loading: false,
          pLang: data.dataset.code_snippets[0]?.lang,
        });

        fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/dataset_documentation/${data.dataset.id}`,
        )
          .then()
          .then((documentation_response) => documentation_response.json())
          .then((documentation_data) => {
            this.setState({
              documentation: documentation_data.documentation,
            });
          });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log('error', error);
      });
  }

  setPlang = (tab) => {
    this.setState({
      pLang: tab,
    });
  };

  render() {
    let showMore = false;
    function setToggle(count) {
      if (count > 10) {
        showMore = true;
        return ' hideToggle';
      }
      showMore = false;
      return null;
    }
    function setShow() {
      const excessRows = dataFields.querySelectorAll('.hideToggle');
      excessRows.forEach((row) => {
        if (window.getComputedStyle(row).display === 'none') {
          row.style.setProperty('display', 'revert', 'important');
          document.getElementById('showMore').innerHTML = '-Show Less';
          document.getElementById('show_more_desc').innerHTML = '';
        } else {
          row.style.setProperty('display', 'none', 'important');
          document.getElementById('showMore').innerHTML = '+Show all data fields';
          document.getElementById('show_more_desc').innerHTML = 'Showing first 10 fields';
        }
      });
    }
    function skips(link) {
      switch (link) {
        case 'about':
          document.getElementById('about').scrollIntoView();
          break;
        case 'fields':
          document.getElementById('fields').scrollIntoView();
          break;
        case 'dataPreview':
          document.getElementById('dataPreview').scrollIntoView();
          break;
        case 'queryBuilder':
          document.getElementById('queryBuilder').scrollIntoView();
          break;
        case 'codeSnippets':
          document.getElementById('codeSnippets').scrollIntoView();
          break;
        case 'description':
          document.getElementById('description').scrollIntoView();
          break;
        case 'aboutDataset':
          document.getElementById('aboutDataset').scrollIntoView();
          break;
        default:
      }
    }
    return this.state.loading ? (
      <Col className="text-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Col>
    ) : this.state.dataset ? (
      <>
        <div className="pre-content">
          <div>
            <div id="block-opa-theme-breadcrumbs">
              <div id="breadcrumbs-container" className="container">
                <nav role="navigation" aria-labelledby="system-breadcrumb">
                  <h2 id="system-breadcrumb" className="visually-hidden">
                    Breadcrumb
                  </h2>
                  <ul className="breadcrumbs">
                    <li>
                      <Link to="/">Home</Link>
                      <span className="breadcrumbs-separator">
                        <svg
                          className="svg-inline--fa fa-chevron-right fa-w-10"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="chevron-right"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          data-fa-i2svg
                        >
                          <path
                            fill="currentColor"
                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                          />
                        </svg>
                      </span>
                    </li>
                    <li>
                      <Link to="/datasets">Datasets</Link>
                      <span className="breadcrumbs-separator">
                        <svg
                          className="svg-inline--fa fa-chevron-right fa-w-10"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="chevron-right"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          data-fa-i2svg
                        >
                          <path
                            fill="currentColor"
                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                          />
                        </svg>
                      </span>
                    </li>
                    <li>
                      <span>{this.state.name}</span>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <Row className="align-items-center">
                <Col sm={12} lg={8}>
                  <h1>{this.state.name}</h1>
                </Col>
              </Row>
              <Row>
                <Col sm={8}>
                  <Row className="jusify-content-center menuLinks">
                    <a
                      className="xs-p-0"
                      onClick={() => skips('description')}
                      href="javascript:void"
                    >
                      Description
                    </a>
                    |
                    <a
                      className=" xs-p-0"
                      onClick={() => skips('aboutDataset')}
                      href="javascript:void"
                    >
                      About this Dataset
                    </a>
                    |
                    <a
                      className="xs-p-0"
                      onClick={() => skips('fields')}
                      href="javascript:void"
                    >
                      Fields
                    </a>
                    |
                    <a
                      className="xs-p-0"
                      onClick={() => skips('dataPreview')}
                      href="javascript:void"
                    >
                      Data Preview
                    </a>
                    |
                    <a
                      className="xs-p-0"
                      onClick={() => skips('queryBuilder')}
                      href="javascript:void"
                    >
                      API Query Builder
                    </a>
                    |
                    <a
                      className="xs-p-0"
                      onClick={() => skips('codeSnippets')}
                      href="javascript:void"
                    >
                      Code Snippets
                    </a>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <a id="description" tabIndex={-1} className="h2Link">
                    Description
                  </a>
                </Col>
              </Row>
              <Row>
                {this.state.dataset.description ? (
                  this.state.dataset.description.length > 300 ? (
                    <Col lg={9}>
                      <div id="module">
                        <p
                          className="collapse mb-2"
                          id="collapseExample"
                          aria-expanded="false"
                        >
                          {' '}
                          {this.state.dataset.description}
                        </p>
                        <a
                          role="button"
                          className="collapsed"
                          data-toggle="collapse"
                          data-target="#collapseExample"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                          href="javascript:0"
                        />
                      </div>
                    </Col>
                  ) : (
                    <Col lg={9}>{this.state.dataset.description}</Col>
                  )
                ) : (
                  <Col lg={9}>Dataset has no description</Col>
                )}
              </Row>
              <Row id="about">
                <Col lg={12} className="mt-4">
                  <a id="aboutDataset" tabIndex={-1} className="h2Link">
                    About this dataset
                  </a>
                </Col>
                <Col>
                  <p>
                    <b>Agency: </b>
                    {this.state.dataset.agency.name}
                    {`(${this.state.dataset.agency.abbr})`}
                  </p>
                  <p>
                    <b>Update Frequency: </b>
                    {this.state.dataset.frequency}
                  </p>
                  {/* Post Launch */}
                  {/* <p>
                    <b>Notes </b>
                    {}
                  </p> */}
                  {/* <p>
                    <b>Records: </b>
                    {}
                  </p> */}
                  <p>
                    <b>Fields: </b>
                    {this.state?.dataset?.dataset_metadatum.length}
                  </p>
                  <p>
                    <b>Tags: </b>
                    {this.state.dataset.tag_list.map((tag) => (
                      <span className="px-1 mx-1 pb-1 small rounded agency_abv">
                        {tag.toUpperCase()}
                      </span>
                    ))}
                  </p>
                </Col>
              </Row>
              <Row className="contact_link_holder">
                <Col>
                  <a className="contact_link" href="/contact-us">
                    Contact us about this dataset
                  </a>
                </Col>
              </Row>
              {this.state.dataset.dataset_metadatum.length > 0 ? (
                <>
                  <Row>
                    <Col lg={12}>
                      <a id="fields" tabIndex={-1} className="h2Link">
                        Fields
                      </a>
                    </Col>
                    <Col lg={9}>
                      <table id="dataFields" className="table table-sm">
                        <thead>
                          <tr>
                            <th scope="col">Field Name</th>
                            <th scope="col">Field Description</th>
                            <th scope="col">Intended Data Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.dataset.dataset_metadatum.map(
                            (dic, idx) => (
                              <tr key={idx} className={`${setToggle(idx)}`}>
                                <td>{dic.column_name}</td>
                                <td>
                                  {' '}
                                  {dic.column_desc !== null
                                    ? HTMLReactParser(dic.column_desc)
                                    : dic.column_desc}
                                </td>
                                <td>{dic.intended_datatype}</td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  {showMore ? (
                    <>
                      <p id="show_more_desc">Showing first 10 fields</p>
                      <a
                        className="showMore"
                        id="showMore"
                        onClick={setShow}
                        onKeyDown={(event) => {
                          if (event.keyCode === 13) {
                            setShow();
                          }
                        }}
                        tabIndex={0}
                      >
                        +Show all data fields
                      </a>
                    </>
                  ) : null}
                </>
              ) : null}
              <div>
                <br />
                <b>Get our detailed metadata:</b>
                <br />
                {this.props.currentUser ? (
                  <p>
                    {this.props.tokens
                      ? this.props.tokens.map((token) => (token.is_default ? (
                        <p>
                          <a
                            href={`${process.env.REACT_APP_SERVER_BASE_URL}/get/${this.state.dataset.agency.abbr}/${this.state.dataset.api_url}/csv/metadata?X-API-KEY=${token.key}`}
                          >
                            {`${process.env.REACT_APP_SERVER_BASE_URL}/get/${this.state.dataset.agency.abbr}/${this.state.dataset.api_url}/csv/metadata?X-API-KEY=${token.key}`}
                          </a>
                        </p>
                      ) : null))
                      : null}
                  </p>
                ) : (
                  <>
                    <p>
                      {`${process.env.REACT_APP_SERVER_BASE_URL}/get/${this.state.dataset.agency.abbr}/${this.state.dataset.api_url}/csv/metadata?X-API-KEY=<YOUR_API_KEY>`}
                    </p>
                    <p>
                      <a href="/registration">Register or sign-in</a> to get
                      your api key.
                    </p>
                  </>
                )}
              </div>
              <div className="data_preview_text">
                <a id="dataPreview" tabIndex={-1} className="h2Link">
                  Data Preview
                </a>
              </div>
              {this.state.loading ? (
                <Col className="text-center">
                  <p>Data Preview Loading...</p>
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Data Preview Loading...</span>
                  </Spinner>
                </Col>
              ) : this.state.dataPreview ? (
                <Row>
                  <div
                    className="border-right border-left p-0 mx-3"
                    style={{ overflow: 'scroll' }}
                  >
                    <div className="table-responsive">
                      <table id="dataPreview" className="table table-sm mb-0">
                        <thead>
                          <tr className="d-flex flex-nowrap">
                            {Object.keys(this.state.dataPreview?.data[0]).map(
                              (heading, index) => (
                                <th
                                  // className="col-1"
                                  id={`heading${index}`}
                                  style={{
                                    wordWrap: 'break-word',
                                    width: '140px',
                                  }}
                                  scope="col"
                                >
                                  {heading}
                                </th>
                              ),
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.dataPreview.data.map((info, idx) => (
                            <tr
                              key={idx}
                              id={idx}
                              className="d-flex flex-nowrap"
                            >
                              {Object.keys(info).map((tData) => (
                                <td
                                  style={{
                                    wordWrap: 'break-word',
                                    width: '140px',
                                  }}
                                >
                                  {info[tData]}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Row>
              ) : (
                <h2>No data preview for this dataset</h2>
              )}
              {this.state.dataset ? (
                <Row>
                  <Col xs={12}>
                    <div className="api_builder_text">
                      <a id="queryBuilder" tabIndex={-1} className="h2Link">
                        API Query Builder
                      </a>
                    </div>
                    <p>
                      The API Query Builder provides you with a basic template
                      for making API calls and allows you to interactively
                      select which fields and records you want, choose how
                      records are formatted and sorted, and generate a proper
                      request URL and curl command that you can use to get your
                      desired data. You must{' '}
                      <a href="/registration"> sign in</a> to your account or
                      click on the Authorize button below and provide{' '}
                      <a href="/api-keys">your key</a> to retrieve data. If you
                      are a new API user, you need to{' '}
                      <a href="/registration">register</a>.
                    </p>
                    <div>
                      <img
                        className="camera_icon"
                        src={camera}
                        alt="video camera icon"
                      />
                      <a className="tutorial_link" href="/video-tutorials">
                        Video Tutorials
                      </a>
                    </div>
                    <hr />
                  </Col>
                  {this.props.tokens.length > 0 ? (
                    <div className="w-100">
                      <SwaggerUI
                        url={`${process.env.REACT_APP_SERVER_BASE_URL}/dataset_documentation/${this.state.dataset.id}`}
                        defaultModelsExpandDepth={-1}
                        docExpansion="list"
                        onComplete={(s) => {
                          this.props.setApiKeyInSwagger(s);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="w-100">
                      <SwaggerUI
                        url={`${process.env.REACT_APP_SERVER_BASE_URL}/dataset_documentation/${this.state.dataset.id}`}
                        defaultModelsExpandDepth={-1}
                        docExpansion="list"
                        onComplete={(s) => {
                          s.authActions.authorize = () => {
                            this.props.history.push('/registration');
                            this.props.setPrevPath(
                              this.props.location.pathname,
                            );
                          };
                        }}
                      />
                    </div>
                  )}
                </Row>
              ) : (
                <h2 className="mt-3">No API Documentation for this dataset</h2>
              )}
              {this.state.dataset.code_snippets.length > 0 && (
                <Row>
                  <Col className="snippet_holder" xs={12}>
                    <a id="codeSnippets" tabIndex={-1} className="h2Link">
                      Code Snippets
                    </a>
                  </Col>
                  <Col lg={12}>
                    <p>
                      Use Code Snippets to connect to the API with popular
                      programming languages. These snippets will allow you to
                      get dataset metadata and a 10-record preview of the data.
                    </p>
                    <p>
                      You can customize snippets for data requests by:
                      <ul className="codeList">
                        <li>modifying the limit to return more records or</li>
                        <li>
                          adding more parameters described in the API Query
                          Builder which include "fields", "sort", "sort_by",
                          "filter_object", and "offset".
                        </li>
                      </ul>
                    </p>
                    <p>
                      For help setting up more advanced queries, try out our{' '}
                      <a className="apiExamples" href="/api-examples">
                        API Examples
                      </a>
                      .
                    </p>
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={this.state.pLang}
                      onSelect={(k) => this.setPlang(k)}
                      onClick={(event) => event.stopPropagation()}
                    >
                      {this.state.dataset.code_snippets.map((codeInfo) => (
                        <Tab eventKey={codeInfo.lang} title={codeInfo.lang}>
                          <SyntaxHighlighter
                            language={codeInfo.lang.toLowerCase()}
                            style={docco}
                          >
                            {codeInfo.code}
                          </SyntaxHighlighter>
                        </Tab>
                      ))}
                    </Tabs>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </>
    ) : (
      <Container>
        <Row>
          <Col>
            <h1>No Dataset Found</h1>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withRouter(Detail);
