/* eslint-disable */
import { CopyBlock } from "react-code-blocks";
function CodeBlocks(props) {
  const language = props.language;
  const snippetContentPython = props.snippetContentPython;
  const snippetContentR = props.snippetContentR;
  const snippetContent = props.snippetContent;
  const configCodeBlock = props.configCodeBlock;

  const customTheme = {
    lineNumberColor: `#333333`,
    lineNumberBgColor: `white`,
    backgroundColor: `#f8f9fa`,
    textColor: `#333333`,
    substringColor: `#333333`,
    keywordColor: `#a71d5d`,
    attributeColor: `#0086b3`,
    selectorAttributeColor: `#a71d5d`,
    docTagColor: `#333333`,
    nameColor: `#63a35c`,
    builtInColor: `#333333`,
    literalColor: `#0086b3`,
    bulletColor: `#0086b3`,
    codeColor: `#333333`,
    additionColor: `#55a532`,
    regexpColor: `#333333`,
    symbolColor: `#0086b3`,
    variableColor: `#df5000`,
    templateVariableColor: `#df5000`,
    linkColor: `#0366d6`,
    selectorClassColor: `#795da3`,
    typeColor: `#a71d5d`,
    stringColor: `#df5000`,
    selectorIdColor: `#795da3`,
    quoteColor: `#df5000`,
    templateTagColor: `#333333`,
    deletionColor: `#bd2c00`,
    titleColor: `#795da3`,
    sectionColor: `#63a35c`,
    commentColor: `green`,
    metaKeywordColor: `#333333`,
    metaColor: `#969896`,
    functionColor: `#333333`,
    numberColor: `#333333`,
  };

  const check = {
    filterByMultiPython: `# Last updated on 11/15/2024 using Python 3.9.

# Import libraries
import requests, pandas as pd, json

# create a filter condition
def create_filter_condition(field, operator, value):
    condition = {"field": field,"operator": operator,"value": value}
    
    return json.dumps(condition)

# combine multiple filter conditions
def combine_filter_conditions(conditions=None, operator='and'):
    conditions = "{" + f'"{operator}":' + f'{conditions}'.replace("'", "") + "}"
    
    return conditions

# filter condition 1
filter_1 = create_filter_condition("industry","eq","B")

# filter condition 2
filter_2 = create_filter_condition("year","eq","2020")

# combine filters using the "and" operator
combined_filter = combine_filter_conditions([filter_1,filter_2], operator="and")


url = "https://apiprod.dol.gov/v4/get/trng/training_dataset_industries/json"

params = {"X-API-KEY":"<YOUR_API_KEY>",
         "limit":"10000",
         "offset":"0",
         "fields":None,
         "sort":None,
         "sort_by":None,
         "filter_object":combined_filter
}
          
request = requests.get(url, params=params)
data_json = request.json()["data"]

data = pd.DataFrame(data_json)`,
    filterByMultiR: `# Last updated on 11/15/2024 using R 4.1.3. 

# Import libraries
library(httr)
library(jsonlite)

# filter condition 1
filter_1 <- list(
  field = "industry",
  operator = "eq",
  value = "B"
)

# filter condition 2
filter_2 <- list(
  field = "year",
  operator = "eq",
  value = "2020"
)

# combine filters using the "and" operator
combined_filter <- list(
  and = list(
    filter_1,
    filter_2
  )
)

url = "https://apiprod.dol.gov/v4/get/trng/training_dataset_industries/json"

params = list(
  "X-API-KEY" = "<YOUR_API_KEY>", 
  "limit" = "10000",
  "offset" = "0",
  "fields" = NULL,
  "sort" = NULL,
  "sort_by" = NULL,
  "filter_object" = toJSON(combined_filter, auto_unbox = TRUE)
)

data_request = GET(url, query=params)
data = as.data.frame(fromJSON(content(data_request, "text")))`,
    filterBySinglePython: `# Last updated on 11/15/2024 using Python 3.9.

# Import libraries
import requests, pandas as pd, json

# Below are several individual filter examples that you can try out with the filter_object parameter.
# create a filter condition
def create_filter_condition(field, operator, value):
    condition = {"field": field,"operator": operator,"value": value}
    
    return json.dumps(condition)

# example: equals (eq) / not equals (neq) operator
filter_1 = create_filter_condition("industry","eq","B")

# example: greater than (gt) / less than (lt)
filter_2 = create_filter_condition("value","gt","999")

# example: in / not_in
filter_3 = create_filter_condition("industry","in",["A","B","C"])

# example: like
filter_4 = create_filter_condition("industry","like","%A%")

url = "https://apiprod.dol.gov/v4/get/trng/training_dataset_industries/json"

params = {"X-API-KEY":"<YOUR_API_KEY>",
         "limit":"10000",
         "offset":"0",
         "fields":None,
         "sort":None,
         "sort_by":None,
         "filter_object":filter_1  # Swap filter_1 with any of the other filters (2-4) to see different results.
}
          
request = requests.get(url, params=params)
data_json = request.json()["data"]

data = pd.DataFrame(data_json)`,
    filterBySingleR: `# Last updated on 11/15/2024 using R 4.1.3. 

# Import libraries
library(httr)
library(jsonlite)

# Below are several individual filter examples that you can try out with the filter_object parameter.
# example: equals (eq) / not equals (neq) operator
filter_1 <- list(
  field = "industry",
  operator = "eq",
  value = "B"
)

# example: greater than (gt) / less than (lt)
filter_2 <- list(
  field = "value",
  operator = "gt",
  value = "999"
)

# example: in / not_in
filter_3 <- list(
  field = "industry",
  operator = "in",
  value = c("A","B","C")
)

# example: like
filter_4 <- list(
  field = "industry",
  operator = "like",
  value = "%A%"
)

url = "https://apiprod.dol.gov/v4/get/trng/training_dataset_industries/json"

params = list(
  "X-API-KEY" = "<YOUR_API_KEY>", 
  "limit" = "10000",
  "offset" = "0",
  "fields" = NULL,
  "sort" = NULL,
  "sort_by" = NULL,
  "filter_object" = toJSON(filter_1, auto_unbox = TRUE) # Swap filter_1 with any of the other filters (2-4) to see different results.
)

data_request = GET(url, query=params)
data = as.data.frame(fromJSON(content(data_request, "text")))`,
    sortPython: `# Last updated on 11/15/2024 using Python 3.9.

# Import libraries
import requests, pandas as pd

url = "https://apiprod.dol.gov/v4/get/trng/training_dataset_industries/json"

params = {"X-API-KEY":"<YOUR_API_KEY>",
         "limit":"10000",
         "offset":"0",
         "fields":None,
         "sort":"desc",
         "sort_by":"year",
         "filter_object":None
}
          
request = requests.get(url, params=params)
data_json = request.json()["data"]

data = pd.DataFrame(data_json)`,
    sortR: `# Last updated on 11/15/2024 using R 4.1.3. 

# Import libraries
library(httr)
library(jsonlite)

url = "https://apiprod.dol.gov/v4/get/trng/training_dataset_industries/json"

params = list(
  "X-API-KEY" = "<YOUR_API_KEY>", 
  "limit" = "10000",
  "offset" = "0",
  "fields" = NULL,
  "sort" = "desc",
  "sort_by" = "year",
  "filter_object" = NULL
)

data_request = GET(url, query=params)
data = as.data.frame(fromJSON(content(data_request, "text")))`,
    selectFieldPython: `# Last updated on 11/15/2024 using Python 3.9.

# Import libraries
import requests, pandas as pd

url = "https://apiprod.dol.gov/v4/get/trng/training_dataset_industries/json"

params = {"X-API-KEY":"<YOUR_API_KEY>",
         "limit":"10000",
         "offset":"0",
         "fields":"industry,value",
         "sort":None,
         "sort_by":None,
         "filter_object":None
}
          
request = requests.get(url, params=params)
data_json = request.json()["data"]

data = pd.DataFrame(data_json)`,
    selectFieldR: `# Last updated on 11/15/2024 using R 4.1.3. 

# Import libraries
library(httr)
library(jsonlite)

url = "https://apiprod.dol.gov/v4/get/trng/training_dataset_industries/json"

params = list(
  "X-API-KEY" = "<YOUR_API_KEY>", 
  "limit" = "10000",
  "offset" = "0",
  "fields" = "industry,value",
  "sort" = NULL,
  "sort_by" = NULL,
  "filter_object" = NULL
)

data_request = GET(url, query=params)
data = as.data.frame(fromJSON(content(data_request, "text")))`,
    limitParamPython: `# Last updated on 11/15/2024 using Python 3.9.

# Import libraries
import requests, pandas as pd

url = "https://apiprod.dol.gov/v4/get/trng/training_dataset_industries/json"

params = {"X-API-KEY":"<YOUR_API_KEY>",
         "limit":"10000",
         "offset":"0",
         "fields":None,
         "sort":None,
         "sort_by":None,
         "filter_object":None
}
          
request = requests.get(url, params=params)
data_json = request.json()["data"]

data = pd.DataFrame(data_json)`,
    limitParamR: `# Last updated on 11/15/2024 using R 4.1.3. 

# Import libraries
library(httr)
library(jsonlite)

url = "https://apiprod.dol.gov/v4/get/trng/training_dataset_industries/json"

params = list(
  "X-API-KEY" = "<YOUR_API_KEY>", 
  "limit" = "10000",
  "offset" = "0",
  "fields" = NULL,
  "sort" = NULL,
  "sort_by" = NULL,
  "filter_object" = NULL
)

data_request = GET(url, query=params)
data = as.data.frame(fromJSON(content(data_request, "text")))`,
    getMetaDataPython: `# Last updated on 11/15/2024 using Python 3.9.

# Import libraries
import requests, pandas as pd

def get_metadata(agency, endpoint, api_key=None):
    if api_key is None:
        raise ValueError("API key must be provided.")

    metadata_url = f"https://apiprod.dol.gov/v4/get/{agency}/{endpoint}/json/metadata?X-API-KEY={api_key}"

    try:
        response = requests.get(metadata_url)
        response.raise_for_status()  # Will raise an HTTPError if status code is 4xx or 5xx
        metadata_json = response.json()
        metadata = pd.DataFrame(metadata_json)
    except requests.RequestException as e:  # Handle errors related to the request
        raise Exception(f"API request failed: {e}")

    return metadata

metadata = get_metadata("trng", "training_dataset_industries", api_key="<YOUR_API_KEY>")`,
    getMetaDataR: `# Last updated on 11/15/2024 using R 4.1.3. 

# Import libraries
library(httr)
library(jsonlite)

# Get metadata
metadata_url = "https://apiprod.dol.gov/v4/get/trng/training_dataset_industries/json/metadata"
params = list("X-API-KEY"="<YOUR_API_KEY>")
metadata_request = GET(metadata_url, query=params)
metadata = as.data.frame(fromJSON(content(metadata_request, "text")))`,
    getDatasetPython: `# Last updated on 11/15/2024 using Python 3.9.

# Import libraries
import requests, pandas as pd

def get_datasets():
    collect = []
    page = 1

    while True:
        url = f"https://apiprod.dol.gov/v4/datasets?page={page}"
        response = requests.get(url)
        datasets_api_json = response.json()["datasets"]        
        if not datasets_api_json:
            break  # Exit the loop if no datasets are returned
        datasets_api = pd.DataFrame(datasets_api_json)
        collect.append(datasets_api)
        page += 1  # Increment the page number to request the next page
    
    datasets = pd.concat(collect, ignore_index=True)
    
    return datasets

datasets = get_datasets()`,
    getDatasetR: `# Last updated on 11/15/2024 using R 4.1.3. 

# Import libraries
library(httr)
library(jsonlite)

all_datasets_list <- list()
page <- 1

while (TRUE) {
  url <- paste0("https://apiprod.dol.gov/v4/datasets?page=", page)
  response <- GET(url)
  data <- fromJSON(content(response, "text"), flatten = TRUE)$data
  if (length(data) == 0) {
    break  # Exit the loop if no datasets are returned
  }
  all_datasets_list[[page]] <- as.data.frame(data)
  page <- page + 1  # Increment the page number to request the next page
}

datasets <- do.call(dplyr::bind_rows, all_datasets_list)`,
  };
  return (
    <>
      <div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              onClick={() => configCodeBlock("Python", snippetContentPython)}
              data-toggle="tab"
              href="#tab1"
              role="tab"
            >
              Python
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              onClick={() => configCodeBlock("R", snippetContentR)}
              data-toggle="tab"
              href="#tab2"
              role="tab"
            >
              R
            </a>
          </li>
        </ul>
      </div>
      <div>
        <CopyBlock
          text={check[snippetContent]}
          language={language}
          showLineNumbers={true}
          theme={customTheme}
          codeBlock={{ lineNumbers: false, wrapLines: true }}
        />
      </div>
    </>
  );
}

export default CodeBlocks;
