import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import '../../css/hero_banner.css';

function HeroBanner({ page }) {
  let mainClass = null;
  let backgroundCSS = null;
  let header = null;
  let header2 = null;
  let textBox = null;
  switch (page) {
    case 'dataset':
      mainClass = 'main_banner_dataset';
      backgroundCSS = 'dataset';
      header = 'Data Catalog';
      textBox = 'text_box';
      break;
    case 'about':
      mainClass = 'main_banner';
      backgroundCSS = 'about';
      header = 'About the Open Data Portal';
      header2 = 'Discover our vision';
      textBox = 'text_box_second_heading';
      break;
    case 'tutorial':
      mainClass = 'main_banner';
      backgroundCSS = 'tutorial';
      header = 'Video Tutorials';
      header2 = 'Learn to find, access, and use our data';
      textBox = 'text_box_second_heading';
      break;
    case 'api':
      mainClass = 'main_banner';
      backgroundCSS = 'api';
      header = 'API Examples';
      header2 = 'Examples of how to integrate our data';
      textBox = 'text_box_second_heading';
      break;
    case 'userGuide':
      mainClass = 'main_banner';
      backgroundCSS = 'user_guide';
      header = 'User Guide';
      header2 = 'Documentation for using our API';
      textBox = 'text_box_second_heading';
      break;
    case 'getting-started':
      mainClass = 'main_banner_gs';
      backgroundCSS = 'started';
      header = 'Getting Started';
      header2 = 'Create an account and access our data';
      textBox = 'text_box_second_heading';
      break;
    case 'visualization':
      mainClass = 'main_banner_vis';
      backgroundCSS = 'visualization';
      header = 'Visualization Gallery';
      header2 = 'Explore trends in our data';
      textBox = 'text_box_second_heading';
      break;
    case 'faq':
      mainClass = 'main_banner';
      backgroundCSS = 'faq';
      header = 'Frequently Asked Questions';
      textBox = 'text_box';
      break;
    case 'homepage':
      mainClass = 'main_banner';
      backgroundCSS = 'homepage';
      header = 'Open Data';
      header2 = 'Explore and download labor and workplace data';
      textBox = 'text_box_second_heading';
      break;
    case 'meta':
      mainClass = 'main_banner';
      backgroundCSS = 'meta';
      header = 'Metadata';
      textBox = 'text_box';
      break;
    default:
  }
  return (
    <div id={mainClass} className={`fluid ${backgroundCSS}`}>
      <div className={textBox}>
        <h1>{header}</h1>
        {header2 ? <h2>{header2}</h2> : null}
      </div>
    </div>
  );
}

export default HeroBanner;
