import React from 'react';
import PreContentComponent from '../data/PreContentComponent';
import HeroBanner from './HeroBanner';
import '../../css/about.css';

function About() {
  return (
    <div className="pre-content">
      <PreContentComponent spanText="About" />
      <HeroBanner page="about" />
      <div className="container">
        <div className="row">
          <div className="col-lg-13 aboutText">
            <h2>Background</h2>
            <p>
              Title II of the{' '}
              <a href="https://www.congress.gov/bill/115th-congress/house-bill/4174">
                Foundations for Evidence-Based Policymaking Act of 2018
              </a>{' '}
              established requirements for Federal programs to expand, enhance,
              and increase the sophistication of the data they make public,
              ensuring data are open by default, machine-readable, have
              metadata, and can be found and accessed through comprehensive data
              inventories. In response, DOL issued{' '}
              <a href="https://www.federalregister.gov/documents/2019/03/26/2019-05720/secretarys-order-02-2019-chief-data-officer-and-dol-data-board">
                Secretary’s Order 02-2019
              </a>{' '}
              establishing a framework to bring a more central focus to the
              quality, consistency, and availability of data to inform and
              influence how DOL carries out its mission, and to support the
              widest possible set of public stakeholder use cases. DOL has
              further embraced this mandate by publishing an{' '}
              <a href="https://www.dol.gov/sites/dolgov/files/Data-Governance/DOL-Enterprise-Data-Strategy-2022.pdf">
                Enterprise Data Strategy
              </a>{' '}
              featuring the FAIR principles as a foundational element, that
              serves as a roadmap for how we will meet these challenges and
              accomplish these goals: aligning data operations across many
              different programs, establishing modernized, consolidated
              shared-services for data , and ensuring support for automation,
              comprehensibility, and ease of customer use.
            </p>
            <p>
              DOL is committed to making these data assets open by default and
              discoverable through a comprehensive data inventory that will
              serve as a clearinghouse of supporting information about the data
              including content, metadata, and names of data stewards and
              custodians to support data use. DOL data inventories and the data
              described within them will be openly accessible to the greatest
              extent possible, in accordance with Federal privacy and security
              laws. DOL’s open data are intended to be easily discoverable and
              accessible, and can be freely used, reused, and redistributed by
              anyone.
            </p>
            <h3>About the Open Data Portal</h3>
            <p>
              The Open Data Portal provides the public with a simple,
              predictable, and consistent way for finding and accessing our open
              data. You may explore our data in the{' '}
              <a href="/datasets">Data Catalog</a> and use our Application
              Programming Interfaces (APIs) to extract relevant subsets of data,
              on-demand and in both machine-readable and machine-actionable
              formats. Each API data endpoint also has a{' '}
              <a href="/metadata">metadata</a> endpoint, which offers
              tremendous value in describing the individual variables within the
              larger file, and providing critical information on content format,
              missing data and errata, descriptive statistics, and decoding of
              specific data types.{' '}
            </p>
            <p>
              The API allows for direct integration of our data into business
              intelligence, analytical software, and visualization systems;
              supports automation of data use; and provides a single method for
              accessing all of the data available, regardless of its source,
              storage format, origin, or content.{' '}
            </p>
            <p>
              To start discovering our Open Data, visit the{' '}
              <a href="/getting-started">Getting Started Page</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
