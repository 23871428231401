import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Container, Alert, Row, Col, Button,
} from 'react-bootstrap';

function EmailVerify(props) {
  let message = '';
  if (props.location.state) {
    message = props.location.state.message;
  }
  return (
    <div
      className="views-element-container"
      id="block-views-block-homepage-hero-block-4"
    >
      <div className="block-views-block-homepage-hero-block-4">
        <div
          id="main-content"
          tabIndex={-1}
          className="basic-title-banner-title-container"
        >
          <Row className="justify-content-center">
            <Col lg={6}>
              <Alert variant="success">{message}</Alert>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={2}>
              <Button onClick={() => props.history.push('/api-keys')} block>
                Go to API Keys
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default withRouter(EmailVerify);
