/* eslint-disable import/no-extraneous-dependencies */
import { React, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import $ from 'jquery';
import PreContentComponent from '../data/PreContentComponent';
import HeroBanner from './HeroBanner';
import TutorialText from './TutorialText';
import '../../css/tutorial.css';

function Tutorials() {
  const [text, setText] = useState('browseData');
  function menuClick(text) {
    $('#transcript_toggle_button').text('Show transcript');
    $('#transcript_container').hide();
    if (!$(`#${text}`).hasClass('first')) {
      $('.first').css({
        border: '1px solid black',
        'background-color': '#FFF',
      });
    } else {
      $('.first').css({
        borderLeft: '8px solid #045EA3',
        'background-color': '#F1F1F1',
      });
    }
    setText(text);
  }
  return (
    <div className="pre-content">
      <PreContentComponent spanText="About" />
      <HeroBanner page="tutorial" />
      <div className="container">
        <Row>
          <Col lg={3} id="meta_nav">
            <Nav
              defaultActiveKey="/home"
              id="meta_menu"
              className="flex-column"
            >
              <Nav.Link
                className="active first"
                id="browseData"
                onClick={() => menuClick('browseData')}
                eventKey="link-1"
                href="javascript:void(0)"
              >
                How to browse datasets in the Data Catalog
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick('registerApiKey')}
                id="registerApiKey"
                eventKey="link-2"
                href="javascript:void(0)"
              >
                How to register for an API key to access metadata and data
              </Nav.Link>
              <Nav.Link
                onClick={() => menuClick('queryBuilder')}
                eventKey="link-3"
                id="queryBuilder"
                href="javascript:void(0)"
              >
                How to access data with the API Query Builder
              </Nav.Link>
            </Nav>
          </Col>
          <Col lg={7} id="meta_text_section">
            <TutorialText type={text} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Tutorials;
