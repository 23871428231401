import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import HTMLReactParser from 'html-react-parser';
import { Container, Row, Col } from 'react-bootstrap';
import PreContentComponent from './data/PreContentComponent';
import HeroBanner from './StaticComponents/HeroBanner';
import ContentComponent from './data/ContentComponent';
import '../css/faq.css';

function FAQs() {
  const [dataWithActiveState, setDataWithActiveState] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_DATA_PROXY_URL}/faqs`)
      .then((res) => res.json())
      .then((data) => {
        setDataWithActiveState(() => data.faqs.map((el) => ({ ...el, isActive: false })));
        setLoading(false);
      });
  }, []);

  const toggleActiveAccortionItem = (idx) => {
    dataWithActiveState[idx] = {
      ...dataWithActiveState[idx],
      isActive: !dataWithActiveState[idx].isActive,
    };
    setDataWithActiveState([...dataWithActiveState]);
  };
  return (
    <>
      <PreContentComponent spanText="FAQ" />
      <HeroBanner page="faq" />
      <Container>
        <br />
        {loading && (
          <Col className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Col>
        )}
        <br />
        {dataWithActiveState.map((faq, idx) => (
          <Row key={idx} className="justify-content-center mb-md-2">
            <Col id="faq" sm={7}>
              <a
                data-toggle="collapse"
                className="btn btn-light col-12"
                data-target={`#collapse${idx}`}
                aria-expanded="false"
                aria-controls={`collapse${idx}`}
                href="javascript.void()"
                role="button"
                onClick={() => {
                  toggleActiveAccortionItem(idx);
                }}
              >
                <Row className="justify-content-between border-bottom-secondary">
                  <Col xs={9} lg={10} className="text-left">
                    <u>
                      <b>{faq.question}</b>
                    </u>
                  </Col>
                  <Col xs={2} lg={2} className="text-right">
                    {faq.isActive === true ? (
                      <h3 className="mb-1">-</h3>
                    ) : (
                      <h3 className="mb-1">+</h3>
                    )}
                  </Col>
                </Row>
              </a>
            </Col>
            <Col sm={7}>
              <div className="collapse" id={`collapse${idx}`}>
                <div id="faqAnswer" className="mb-3 pb-2 mx-3 border-bottom">
                  {HTMLReactParser(faq.answer)}
                </div>
              </div>
            </Col>
          </Row>
        ))}
      </Container>
    </>
  );
}
export default FAQs;
