import React from 'react';
import ContentComponent from './data/ContentComponent';

function PageNotFound() {
  return (
    <ContentComponent
      mainHeader="404: Page not found"
      identifier=""
      className="basic-title-banner-title-container"
      spanText="Page not found"
      centered={false}
    />
  );
}

export default PageNotFound;
