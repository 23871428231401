/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { Formik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import {
  Button, Form, Col, Row, Container, Modal,
} from 'react-bootstrap';
import ContentComponent from './data/ContentComponent';

class AskaQuestion extends React.Component {
  constructor() {
    super();
    this.state = {
      status: '',
      hidden: true,
      renderForm: false,
      user: '',
      show: false,
      datasets: null,
      dataSetLoading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.currentUser) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
    this.props.currentUser
      && this.setState({ user: this.props.currentUser.email });

    setTimeout(() => {
      this.setState({ renderForm: true });
    }, [0]);
    this.getDataSetsFunc();
  }

  getDataSetsFunc = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_DATA_PROXY_URL}/datasets/list`,
    );
    const datasets = await response.data;
    this.setState({ datasets, dataSetLoading: true });
  };

  setShow = (bool) => this.setState({ show: bool });

  handleClose = () => this.setShow(false);

  render() {
    return (
      <>
        <Helmet>
          <title>Contact Us</title>
          <meta
            name="description"
            content="This is a different description for this route."
          />
        </Helmet>
        <ContentComponent
          mainHeader="Contact Us"
          className="basic-title-banner-title-container"
          spanText="Contact Us"
          centered
        />
        <Container>
          <main role="main">
            <Row className="justify-content-center">
              <Col lg={8}>
                <Modal show={this.state.show} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Woohoo, you're reading this text in a modal!
                  </Modal.Body>
                </Modal>
              </Col>
              <Col lg="8">
                <Formik
                  initialValues={{
                    fname: '',
                    lname: '',
                    email: this.state.user,
                    subject: '',
                    topic: 'General Question',
                    subjectbody: '',
                    recaptcha: '',
                    datasets: '',
                  }}
                  enableReinitialize
                  validationSchema={Yup.object().shape({
                    fname: Yup.string()
                      .trim()
                      .min(2, 'Minimum length is 2 characters')
                      .max(100, 'its too long')
                      .matches(
                        /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
                        'Please enter a valid first name (alphabetic characters)',
                      )
                      .required('First name is required'),
                    lname: Yup.string()
                      .trim()
                      .min(2, 'Minimum length is 2 characters')
                      .max(100, 'its too long')
                      .matches(
                        /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
                        'Please enter a valid last name (alphabetic characters)',
                      )
                      .required('Last name is required'),
                    email: Yup.string()
                      .email('Must be a valid email')
                      .max(255, 'its too long')
                      .required('Email is required'),
                    topic: Yup.string()
                      .oneOf(
                        [
                          'Dataset Question',
                          'General feedback / questions',
                          'Feedback',
                          'Metadata feedback',
                          'Share a link to your code repository',
                          'Suggest a dataset',
                          'Technical question',
                          'API service question',
                        ],
                        'invalid response',
                      )
                      .required('Category is required'),
                    // datasetValues: Yup.string().required('Data Set is required!'),
                    subject: Yup.string()
                      .trim()
                      .min(2, 'Minimum length is 2 characters')
                      .max(100, 'Maximum length is 100 characters')
                      .required('Subject is required'),
                    subjectbody: Yup.string()
                      .trim()
                      .min(2, 'Minimum length is 2 characters')
                      .max(600, 'Maximum length is 600 characters')
                      .required('Body is required'),
                    recaptcha:
                      !this.props.currentUser
                      && Yup.string().required(
                        'Please verify that you are not a robot.',
                      ),
                  })}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);

                    setSubmitting(false);
                    resetForm();
                    const formdata = new FormData();

                    // Fixing object for submit
                    delete values.recaptcha;
                    let newObj = {};
                    if (values.datasets === '') {
                      delete values.datasets;
                      newObj = { ...values, datasets: 'NONE' };
                    } else {
                      const datasetVal = values.datasets
                        .map((datasetValue) => datasetValue.name)
                        .join(', ');
                      delete values.datasets;
                      newObj = { ...values, datasets: datasetVal };
                    }
                    const val = JSON.stringify(newObj);

                    const requestOptions = {
                      method: 'POST',
                      body: val,
                      redirect: 'follow',
                    };

                    fetch(
                      `${process.env.REACT_APP_DATA_PROXY_URL}/user_questions`,
                      requestOptions,
                    )
                      .then((response) => response.json())
                      .then((result) => {
                        this.setState({ status: 'success', submitted: true });
                        this.setShow(true);
                        resetForm();
                      })
                      .catch((error) => {
                        this.setState({ status: 'error', submitted: true });
                        this.setShow(true);
                        resetForm();
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit} noValidate>
                      {this.state.submitted ? (
                        this.state.status === 'success' ? (
                          <Modal
                            show={this.state.show}
                            onHide={this.handleClose}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Success</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              Your question has been successfully submitted.
                            </Modal.Body>
                          </Modal>
                        ) : (
                          <Modal
                            show={this.state.show}
                            onHide={this.handleClose}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Failure</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              Your question was not submitted!
                            </Modal.Body>
                          </Modal>
                        )
                      ) : null}
                      <Form.Row className="justify-content-center">
                        <Form.Group as={Col} md={8} sm={10}>
                          <Form.Label htmlFor="firstName" className="mt-2">
                            First name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="fname"
                            id="firstName"
                            aria-label="firstname"
                            placeholder="Enter your first name"
                            onChange={handleChange}
                            value={values.fname}
                            onBlur={handleBlur}
                            alt="first name input field"
                            isInvalid={touched.fname && errors.fname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row className="justify-content-center">
                        <Form.Group as={Col} md={8} sm={10}>
                          <Form.Label htmlFor="lastName" className="mt-2">
                            Last name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="lname"
                            id="lastName"
                            placeholder="Enter your last name"
                            onChange={handleChange}
                            value={values.lname}
                            onBlur={handleBlur}
                            alt="last name input field"
                            isInvalid={touched.lname && errors.lname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.lastName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row className="justify-content-center">
                        <Form.Group as={Col} md={8} sm={10}>
                          <Form.Label htmlFor="email" className="mt-2">
                            Email
                          </Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter your email"
                            onChange={handleChange}
                            value={values.email}
                            onBlur={handleBlur}
                            alt="email input field"
                            isInvalid={touched.email && errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row className="justify-content-center">
                        <Form.Group as={Col} md={8} sm={10}>
                          {this.state.renderForm && (
                            <>
                              <Form.Label htmlFor="category" className="mt-2">
                                Category
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="topic"
                                id="category"
                                onChange={handleChange}
                                value={values.category}
                                onBlur={handleBlur}
                                alt="question category"
                                isInvalid={touched.topic && errors.topic}
                                style={{ height: '70px' }}
                              >
                                <option value="Select a Category">
                                  Select a Category
                                </option>
                                <option value="Dataset Question">
                                  Dataset Question
                                </option>
                                <option value="General feedback / questions">
                                  General feedback / questions
                                </option>
                                <option value="Metadata feedback">
                                  Metadata feedback
                                </option>
                                <option value="Share a link to your code repository">
                                  Share a link to your code repository
                                </option>
                                <option value="Suggest a dataset">
                                  Suggest a dataset
                                </option>
                                <option value="Technical question">
                                  Technical question
                                </option>
                                <option value="API service question">
                                  API service question
                                </option>
                              </Form.Control>

                              <Form.Control.Feedback type="invalid">
                                {errors.topic}
                              </Form.Control.Feedback>
                            </>
                          )}
                        </Form.Group>
                        <Form.Group as={Col} md={8} sm={10}>
                          {this.state.dataSetLoading && (
                            <Form.Label className="mt-2">
                              Data Set
                              <Select
                                id="datasetValues"
                                name="datasets"
                                type="text"
                                isMulti
                                className="basic-multi-select"
                                classNamePrefix="select"
                                options={this.state.datasets.sort((b, a) => (this.state.tagsAsc
                                  ? a.name
                                    .toLowerCase()
                                    .localeCompare(b.name.toLowerCase())
                                  : b.name
                                    .toLowerCase()
                                    .localeCompare(a.name.toLowerCase())))}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(option) => setFieldValue('datasets', option)}
                                onBlur={handleBlur}
                                value={values.datasets}
                              />
                            </Form.Label>
                          )}
                          <Form.Control.Feedback type="invalid">
                            {/* {errors.datasetValues} */}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md={8} sm={10}>
                          <Form.Label htmlFor="subject" className="mt-2">
                            Subject
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="Enter your subject"
                            onChange={handleChange}
                            value={values.subject}
                            onBlur={handleBlur}
                            alt="subject input field"
                            isInvalid={touched.subject && errors.subject}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row className="justify-content-center">
                        <Form.Group as={Col} md={8} sm={10} className="m-0">
                          <Row>
                            <Col>
                              <Form.Label htmlFor="body" className="mt-2">
                                Body
                              </Form.Label>
                            </Col>
                            <Col>
                              <Form.Text className="text-right">
                                {values.subjectbody.length <= 600
                                  ? `Remaining characters : ${
                                    600 - values.subjectbody.length
                                  }`
                                  : 'Characters exceeded limit.'}
                              </Form.Text>
                            </Col>
                          </Row>

                          <Form.Control
                            as="textarea"
                            rows="4"
                            name="subjectbody"
                            id="body"
                            placeholder="Include all the information someone would need to answer your question"
                            onChange={handleChange}
                            value={values.subjectbody}
                            onBlur={handleBlur}
                            alt="body input field"
                            isInvalid={
                              touched.subjectbody && errors.subjectbody
                            }
                            style={{ resize: 'none' }}
                            aria-label="Included Information"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.body}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      {!this.props.currentUser && (
                        <Form.Row className="justify-content-center mt-5">
                          <Form.Group>
                            <Recaptcha
                              sitekey="6LfwSvkUAAAAAJHUWW2kp0PbswC0qrgNsksF3bmN"
                              render="explicit"
                              verifyCallback={(response) => {
                                setFieldValue('recaptcha', response);
                              }}
                              onloadCallback={() => {
                                console.log('done loading!');
                              }}
                            />
                            {errors.recaptcha && touched.recaptcha && (
                              <p className="text-danger mt-1">
                                <small>{errors.recaptcha}</small>
                              </p>
                            )}
                          </Form.Group>
                        </Form.Row>
                      )}
                      <Row className="justify-content-center mt-3">
                        {/* <Row > */}
                        <Col lg={4}>
                          <Button
                            type="submit"
                            aria-label="Submit"
                            disabled={isSubmitting}
                            block
                          >
                            Submit
                          </Button>
                        </Col>
                        {/* </Row> */}
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </main>
        </Container>
      </>
    );
  }
}

export default withRouter(AskaQuestion);
